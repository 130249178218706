import {
    MODEL_PQ_AWARD_TAXES_KEYS,
    MODEL_PQ_COMMON_SECOND_STEP_ADDITIONAL_DATA_KEY,
    MODEL_PQ_COMMON_SECOND_STEP_KEYS,
    MODEL_PQ_COMMON_SECOND_STEP_PRICES_ARRAY_KEY,
    MODEL_PQ_COMMON_SECOND_STEP_TOTAL_PRICES_KEYS
} from "@models/priceQuote.model";

export default function ({locState, forms, AlertsManager, getPassengers}) {
    const checkHasOneFilledField = () => {
        const filledFields = [locState.code, locState.pqCodeHasParsed];
        return filledFields.some(item => item)
    }

    const checkFilledFieldsFirstStep = () => {
        const filledFields = [locState.pqCodeHasParsed];
        return filledFields.every(item => item)
    }

    const preValidateFirstStep = () => {
        const isFilledAllFields = checkFilledFieldsFirstStep()
        forms.firstStep?.clearErrors()

        if (!isFilledAllFields) {
            AlertsManager.add('PQ has errors', 'danger')
            if (!locState.pqCodeHasParsed) {
                forms.firstStep.setError(`code`, 'Code cannot be parsed');
            }

        }

        return isFilledAllFields
    }

    const checkFilledFieldsSecondStep = () => {
        const filledFields = [];
        const formData = forms.secondStep.data()

        MODEL_PQ_COMMON_SECOND_STEP_KEYS.forEach(key => {
            if (MODEL_PQ_COMMON_SECOND_STEP_PRICES_ARRAY_KEY === key) {
                const pricesArr = formData[key]
                pricesArr.forEach((priceItem, priceItemIndex) => {
                    getPassengers.value.entries?.forEach(([passengerKey, passengerValue]) => {
                        if (passengerValue > 0) {
                            if (priceItem.type.title == 'Revenue') {
                                filledFields.push(...Object.values(priceItem.data))
                            } else if (priceItem.type.title == 'Award') {
                                filledFields.push(...Object.values(priceItem.data[passengerKey]))
                            }
                        }
                    })
                })
            } else if (MODEL_PQ_COMMON_SECOND_STEP_TOTAL_PRICES_KEYS.includes(key)) {

                if (key == 'total_net_prices') {
                    if (forms.secondStep.prices > 1) {
                        filledFields.push(...Object.values(formData[key]))
                    }
                } else if (key == 'sell_prices') {
                    Object.entries(formData[key]).forEach(([itemKey, itemValue]) => {
                        if (!itemValue) {
                            filledFields.push(itemValue > 0)
                        }
                        // else {
                        //     const netPrice = formData['total_net_prices'][itemKey]
                        //     filledFields.push(Number(itemValue) > Number(netPrice))
                        // }
                    })
                } else {
                    filledFields.push(...Object.values(formData[key]))
                }
            } else if (MODEL_PQ_COMMON_SECOND_STEP_ADDITIONAL_DATA_KEY == key) {
                Object.values(formData[key]).forEach((item,) => {
                    Object.entries(item).forEach(([itemKey, itemValue]) => {
                        if (['airline', 'pcc'].includes(itemKey)) {
                            if (item.type.title == 'Revenue' && itemKey == 'pcc') {
                                filledFields.push(itemValue)
                            } else if (item.type.title == 'Award' && itemKey == 'airline') {
                                filledFields.push(itemValue)
                            }
                        }
                    })
                })
            }
        })
        // console.log('filled', filledFields);
        return filledFields.every(item => item)
    }

    // const preValidateSecondStep = () => {
    //     const isFilledAllFields = checkFilledFieldsSecondStep()
    //     forms.secondStep?.clearErrors()
    //     if (!isFilledAllFields) {
    //         AlertsManager.add('PQ has errors', 'danger')
    //         const setErrorRequired = (formKey, text = 'Required.') => {
    //             forms.secondStep.setError(formKey, text);
    //         }
    //         const formData = forms.secondStep.data()
    //
    //         MODEL_PQ_COMMON_SECOND_STEP_KEYS.forEach(key => {
    //
    //             if (MODEL_PQ_COMMON_SECOND_STEP_PRICES_ARRAY_KEY === key) {
    //                 const pricesArr = formData[key]
    //                 pricesArr.forEach((priceItem, priceItemIndex) => {
    //                     getPassengers.value.entries?.forEach(([passengerKey, passengerValue]) => {
    //                         if (passengerValue > 0) {
    //                             if (priceItem.type.title == 'Revenue') {
    //                                 Object.entries(priceItem.data).forEach(([itemKey, itemValue]) => {
    //                                     if (!itemValue) {
    //                                         setErrorRequired(`${key}.${priceItemIndex}.${itemKey}`)
    //                                     }
    //                                 })
    //                             } else if (priceItem.type.title == 'Award') {
    //                                 MODEL_PQ_AWARD_TAXES_KEYS.forEach(taxKey => {
    //                                     if (!priceItem.data[passengerKey]?.[taxKey]) {
    //                                         setErrorRequired(`${key}.${priceItemIndex}.${passengerKey}.${taxKey}`)
    //                                     }
    //                                 })
    //                             }
    //                         }
    //                     })
    //                 })
    //
    //             } else if (MODEL_PQ_COMMON_SECOND_STEP_TOTAL_PRICES_KEYS.includes(key)) {
    //                 Object.entries(formData[key]).forEach(([itemKey, itemValue]) => {
    //
    //                     if (key == 'total_net_prices') {
    //                         if (forms.secondStep.prices > 1) {
    //                             if (!itemValue) {
    //                                 setErrorRequired(`${key}.${itemKey}`)
    //                             }
    //                         }
    //                     } else if (key == 'sell_prices') {
    //                         if (!itemValue) {
    //                             setErrorRequired(`${key}.${itemKey}`)
    //                             return
    //                         } else {
    //                             const minPrice = locState.minPricesObjectForSell?.[itemKey]
    //                             if(minPrice){
    //                                 if (Number(minPrice) >= Number(itemValue)) {
    //                                     setErrorRequired(`${key}.${itemKey}`, 'Need Min.$' + (minPrice))
    //                                 }
    //                             }
    //                         }
    //                     } else {
    //                         if (!itemValue) {
    //                             setErrorRequired(`${key}.${itemKey}`)
    //                         }
    //                     }
    //                 })
    //             } else if (MODEL_PQ_COMMON_SECOND_STEP_ADDITIONAL_DATA_KEY.includes(key)) {
    //
    //                 Object.values(formData[key]).forEach((item, index) => {
    //                     Object.entries(item).forEach(([itemKey, itemValue]) => {
    //
    //                         if (['pcc', 'mileage'].includes(itemKey)) {
    //                             if (item.type?.title == 'Revenue' && itemKey == 'pcc') {
    //                                 if (!itemValue) {
    //                                     setErrorRequired(`${key}.${index}.${itemKey}`)
    //                                 }
    //                             } else if (item.type?.title == 'Award' && itemKey == 'mileage') {
    //                                 if (!itemValue) {
    //                                     setErrorRequired(`${key}.${index}.${itemKey}`)
    //                                 }
    //                             } else {
    //                                 setErrorRequired(`${key}.${index}.program-pcc`)
    //                             }
    //                         }
    //                         if (itemKey == 'type') {
    //                             if (!itemValue) {
    //                                 setErrorRequired(`${key}.${index}.type`)
    //                             }
    //                         }
    //                     })
    //                 })
    //             }
    //         })
    //     }
    //     return isFilledAllFields
    // }

    const preValidateSecondStep = () => {
        const isFilledAllFields = checkFilledFieldsSecondStep();
        console.log('isFilledAllFields', isFilledAllFields);
        forms.secondStep?.clearErrors();
        if (!isFilledAllFields) {
            AlertsManager.add('PQ has errors', 'danger');
            const setErrorRequired = (formKey, text = 'Required.') => {
                forms.secondStep.setError(formKey, text);
            };
            const setErrorDecimal = (formKey, text = 'Up to 2 decimal places allowed.') => {
                forms.secondStep.setError(formKey, text);
            };
            const formData = forms.secondStep.data();

            const checkDecimalPlaces = (value) => {
                return /^\d+(\.\d{1,2})?$/.test(value);
            };

            MODEL_PQ_COMMON_SECOND_STEP_KEYS.forEach(key => {

                if (MODEL_PQ_COMMON_SECOND_STEP_PRICES_ARRAY_KEY === key) {
                    const pricesArr = formData[key];
                    pricesArr.forEach((priceItem, priceItemIndex) => {
                        getPassengers.value.entries?.forEach(([passengerKey, passengerValue]) => {
                            if (passengerValue > 0) {
                                if (priceItem.type.title == 'Revenue') {
                                    Object.entries(priceItem.data).forEach(([itemKey, itemValue]) => {
                                        if (!itemValue) {
                                            setErrorRequired(`${key}.${priceItemIndex}.${itemKey}`);
                                        } else if (!checkDecimalPlaces(itemValue)) {
                                            setErrorDecimal(`${key}.${priceItemIndex}.${itemKey}`);
                                        }
                                    });
                                } else if (priceItem.type.title == 'Award') {
                                    MODEL_PQ_AWARD_TAXES_KEYS.forEach(taxKey => {
                                        if (!priceItem.data[passengerKey]?.[taxKey]) {
                                            setErrorRequired(`${key}.${priceItemIndex}.${passengerKey}.${taxKey}`);
                                        } else if (!checkDecimalPlaces(priceItem.data[passengerKey][taxKey])) {
                                            setErrorDecimal(`${key}.${priceItemIndex}.${passengerKey}.${taxKey}`);
                                        }
                                    });
                                }
                            }
                        });
                    });

                } else if (MODEL_PQ_COMMON_SECOND_STEP_TOTAL_PRICES_KEYS.includes(key)) {
                    Object.entries(formData[key]).forEach(([itemKey, itemValue]) => {

                        if (key == 'total_net_prices') {
                            if (forms.secondStep.prices > 1) {
                                if (!itemValue) {
                                    setErrorRequired(`${key}.${itemKey}`);
                                } else if (!checkDecimalPlaces(itemValue)) {
                                    setErrorDecimal(`${key}.${itemKey}`);
                                }
                            }
                        } else if (key == 'sell_prices') {
                            if (!itemValue) {
                                setErrorRequired(`${key}.${itemKey}`);
                            } else if (!checkDecimalPlaces(itemValue)) {
                                setErrorDecimal(`${key}.${itemKey}`);
                            }
                            // else {
                            //     const minPrice = locState.minPricesObjectForSell?.[itemKey];
                            //     if (minPrice) {
                            //         if (Number(minPrice) >= Number(itemValue)) {
                            //             setErrorRequired(`${key}.${itemKey}`, 'Need Min.$' + (minPrice));
                            //         }
                            //     }
                            // }
                        }
                        else {
                            if (!itemValue) {
                                setErrorRequired(`${key}.${itemKey}`);
                            } else if (!checkDecimalPlaces(itemValue)) {
                                setErrorDecimal(`${key}.${itemKey}`);
                            }
                        }
                    });
                } else if (MODEL_PQ_COMMON_SECOND_STEP_ADDITIONAL_DATA_KEY.includes(key)) {

                    Object.values(formData[key]).forEach((item, index) => {
                        Object.entries(item).forEach(([itemKey, itemValue]) => {

                            if (['pcc', 'mileage'].includes(itemKey)) {
                                if (item.type?.title == 'Revenue' && itemKey == 'pcc') {
                                    if (!itemValue) {
                                        setErrorRequired(`${key}.${index}.${itemKey}`);
                                    }
                                } else if (item.type?.title == 'Award' && itemKey == 'mileage') {
                                    if (!itemValue) {
                                        setErrorRequired(`${key}.${index}.${itemKey}`);
                                    } else if (!checkDecimalPlaces(itemValue)) {
                                        setErrorDecimal(`${key}.${index}.${itemKey}`);
                                    }
                                } else {
                                    setErrorRequired(`${key}.${index}.program-pcc`);
                                }
                            }
                            if (itemKey == 'type') {
                                if (!itemValue) {
                                    setErrorRequired(`${key}.${index}.type`);
                                }
                            }
                        });
                    });
                }
            });
        }
        return isFilledAllFields;
    }
    return {
        checkHasOneFilledField, preValidateFirstStep, preValidateSecondStep
    }
}
