<script setup>
import {computed, onMounted, ref, watch} from "vue";
import axios from "axios";
import {useForm, usePage} from "@inertiajs/vue3";
import Tiptap from "@boxes/client/BoxClientConnectionActions/modals/components/Tiptap.vue";
import {usePageRequestShowStore} from "@store/pages/page-request-edit";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";
import {useBackendMiddlewarePropsService} from "@services";

// Состояние для активной вкладки
const activeTab = ref('reached'); // По умолчанию активная вкладка - "reached"

const isDialogActive = ref(false);

// Expose the method to open the dialog
const openDialog = () => {
    isDialogActive.value = true;
};

// Expose the necessary properties and methods
defineExpose({ openDialog });

const loading = ref(false);
const search = ref("");
const templates = ref({reached: [], not_reached: []}); // Шаблоны по группам
const emails = ref([]);
const agentEmails = ref([]);
const systemAgentEmails = ref([]);
const openedPanels = ref([]);
const allowSendOther = ref(false);
const allowSendFromSystemUsers = ref(false);

const form = useForm({
    email: '',
    from_email: '',
    subject: '',
    category: '',
    short_name: '',
    email_body: '',
    request_id: null,
    template_id : null
});

const page = usePage();
const store = usePageRequestShowStore();
const categoryOrder = ['Introduction', 'No Response', 'Follow-Up', 'Thank You']; // Задайте нужный порядок категорий

// Фильтрация шаблонов по строке поиска и активной вкладке
const filteredTemplatesByCategory = computed(() => {
    const filtered = templates.value[activeTab.value].filter((template) =>
        template.description.toLowerCase().includes(search.value.toLowerCase())
    );

    // Создаем пустой объект с категориями по порядку
    const grouped = categoryOrder.reduce((groups, category) => {
        groups[category] = [];
        return groups;
    }, {});

    // Заполняем объект шаблонами по категориям
    filtered.forEach((template) => {
        const category = template.category || 'Uncategorized';
        if (!grouped[category]) {
            grouped[category] = [];
        }
        grouped[category].push(template);
    });

    return grouped;
});

const selectTemplate = (template) => {
    form.email_body = replaceVariables(template.email_body);
    form.subject = replaceVariables(template.subject);
    form.category = template.category;
    form.short_name = template.short_name;
    form.template_id = template.id
};

const replaceVariables = (template) => {
    console.log('props', page.props);
    const client = page.props.client.data;
    const agent = page.props.agent[0];
    const isAssignedReq = page.props.agent.length > 0;
    const flight_legs = page.props.currentRequest.data?.flight_legs;
    const cur_req = page.props.currentRequest.data;
    const pqs = page.props.agent_pqs;
    const countSentAndResentQuotes = () => {
        let totalSent = 0;
        const cur_pqs = pqs[cur_req.id];
        if (cur_pqs && cur_pqs.length > 0) {
            cur_pqs.forEach(pq => {
                (pq.data.status === 'Sent' || pq.data.status === 'Re-sent') && totalSent++;
            });
        }
        return totalSent;
    };

    // Функция для определения времени суток
    const getTimeOfTheDay = () => {
        // Получаем текущее время в Нью-Йорке
        const nyTime = new Date().toLocaleTimeString("en-US", { timeZone: "America/New_York" });

        // Разбиваем время на составляющие (формат "hh:mm AM/PM")
        const [time, modifier] = nyTime.split(' ');
        let [hours, minutes] = time.split(':').map(Number);

        // Преобразуем в 24-часовой формат
        if (modifier === 'PM' && hours !== 12) {
            hours += 12;
        } else if (modifier === 'AM' && hours === 12) {
            hours = 0;
        }

        // Определяем утро, день или вечер
        if (hours >= 0 && hours < 12) {
            return "morning";
        } else if (hours >= 12 && hours < 17) {
            return "afternoon";
        } else {
            return "evening";
        }
    };

    const calculateLastQuotesSentDaysAgo = () => {
        const cur_pqs = pqs[cur_req.id];
        let latestSentDate = null;
        console.log(cur_pqs);

        if (cur_pqs && cur_pqs.length > 0) {
            cur_pqs.forEach(pq => {
                // Проверяем наличие записей в "send_logs"
                if (pq.data.send_logs && pq.data.send_logs.length > 0) {
                    pq.data.send_logs.forEach(log => {
                        // Проверяем статус именно внутри лога, а не pq
                        if (log.status === 'Sent') {  // Предполагается, что статус внутри лога
                            const sentDate = new Date(log.send_at);
                            if (!latestSentDate || sentDate > latestSentDate) {
                                latestSentDate = sentDate; // Обновляем на самую позднюю дату
                            }
                        }
                    });
                }
            });

            if (latestSentDate) {
                const currentDate = new Date();
                const latestDate = new Date(latestSentDate);

                // Сравниваем только календарные даты, убираем время
                currentDate.setHours(0, 0, 0, 0);
                latestDate.setHours(0, 0, 0, 0);

                const diffTime = Math.abs(currentDate - latestDate);
                const diffInDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

                if (diffInDays === 0) {
                    return 'today'; // Сообщение отправлено сегодня
                } else if (diffInDays === 1 && latestDate < currentDate) {
                    return 'yesterday'; // Сообщение отправлено вчера
                } else if (diffInDays > 2) {
                    return `${diffInDays} days ago`; // Разница больше 2 дней
                } else {
                    return diffInDays; // Разница от 1 до 2 дней
                }
            }
        }
        return "not Sent"; // Если нет отправленных PQ
    };

    const formatDateToMonthDay = (dateString) => {
        if (!dateString) return '';

        const date = new Date(dateString);

        // Форматируем дату, чтобы получить только месяц и день
        return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
    };
    const lastLeg = flight_legs?.[flight_legs.length - 1];
    const dynamicData = {
        $customerFirstName: client.first_name || "FirstName", //1
        $customerLastName: client.last_name ?? '', //2
        $customerFullName: `${client.first_name ?? ''} ${client.last_name ?? ''}`, //3
        $customerTimeOfTheDay: getTimeOfTheDay(), // 4
        $lastQuotesSentDaysAgo: calculateLastQuotesSentDaysAgo(),
        $totalQuotesSent: countSentAndResentQuotes(), //5
        $departureDate: (flight_legs.length > 0 && formatDateToMonthDay(flight_legs?.[0].flight_date_from)) ?? '', //6
        $returnDate: (lastLeg && formatDateToMonthDay(lastLeg?.flight_date_to)) ?? '', //7
        $arrivalDate: (flight_legs.length > 0 && formatDateToMonthDay(flight_legs?.[0].flight_date_to)) ?? '', //7
        $departureCity: (flight_legs?.[0].departure_airport_object.city?.title ?? flight_legs?.[0].departure_airport_object.title) ?? "Departure City", //8
        $destinationCity: (flight_legs?.[0].arrival_airport_object.city?.title ?? flight_legs?.[0].arrival_airport_object.title) ?? (flight_legs?.[0].departure_airport_object.city?.title ?? flight_legs?.[0].departure_airport_object.title), //9
        $numberOfAdults: cur_req.adults ?? 1, //10
        $numberOfChildren: cur_req.children ?? 0, //11
        $numberOfInfants: cur_req.infant ?? 0,// 12
        $agentFirstName: isAssignedReq ? agent.first_name : page.props.auth_user.work_data.display_name, //13
        $agentLastName: isAssignedReq ? agent.last_name : page.props.auth_user.work_data.display_last_name, // 14
        $agentFullName: isAssignedReq  ? `${agent.first_name} ${agent.last_name}` : `${page.props.auth_user.work_data.display_name} ${page.props.auth_user.work_data.display_last_name}`, //15
        $agentEmail: isAssignedReq ? agent.email : page.props.auth_user.work_data.work_email, //16
        $agentPhone: isAssignedReq ? (agent.phone ? ('+1 ' + agent.phone.phone) : "+1-555-555-5555") : (page.props.auth_user ? ("+1" + page.props.auth_user.work_data.work_phone.phone) : '+1121223113'), //17
    };

    return template.replace(/\$(\w+)/g, (match) => {
        const cleanMatch = match.replace(/[.,:!?]/g, '');
        const value = dynamicData[cleanMatch];
        return value !== undefined && value !== '' ? value : '';
    });
};



watch(() => form.email_body, (newVal) => {
    if (newVal) {
        form.email_body = replaceVariables(newVal);
    }
});
watch(() => form.subject, (newVal) => {
    if (newVal) {
        form.subject = replaceVariables(newVal);
    }
});

const AlertsManager = useAlertsManagerStore();
const backPerms = useBackendMiddlewarePropsService();
const pageRequestShowStore = usePageRequestShowStore();

const sendEmail = async () => {
    loading.value = true;
    let emailBody = form.email_body;

// Заменяем все <p> на <p style="margin: 0;">
    emailBody = emailBody.replace(/<p>/g, '<p style="margin: 0;">');

// Присваиваем обратно в form.email_body, если нужно
    form.email_body = emailBody;

    form.post('/follow-up/send', {
        onSuccess: (e) => {
            loading.value = false;
            loadTemplates();
            form.reset();
            form.request_id = page.props.currentRequest.data.id
            AlertsManager.add(e.props.flash.success.message ?? 'Email sent successfully', 'success');
            pageRequestShowStore.incrementFollowUpEmailCount();
        },
        onError: (errors) => {
            loading.value = false;
            AlertsManager.add('Failed to send', 'danger');
            console.error('Error sending email', errors);
        }
    });
};

const loadTemplates = async () => {
    try {
        loading.value = true;
        const id = page.props.currentRequest.data.id;
        const response = await axios.get(`/api/follow-up/get/${id}`);
        const reachedItems = response.data.reached.items || [];
        const notReachedItems = response.data.not_reached.items || [];
        templates.value = {
            reached: reachedItems,
            not_reached: notReachedItems,
            count_reached: response.data.reached.count ?? 0,
            count_not_reached: response.data.not_reached.count ?? 0,
            count_sent_reached: response.data.reached.sent_count ?? 0,
            count_sent_not_reached: response.data.not_reached.sent_count ?? 0,
            reached_categories: response.data.reached.categories || {},
            not_reached_categories: response.data.not_reached.categories || {}
        };
        loading.value = false;
    } catch (error) {
        console.error("Ошибка при загрузке шаблонов:", error);
        loading.value = false;
    }
};


watch(isDialogActive, (newValue) => {
    if (newValue) {
        loadTemplates();
    }
});

onMounted(() => {
    form.request_id = page.props.currentRequest.data.id
    if (page.props.client && page.props.client.data && page.props.client.data.email) {
        emails.value = page.props.client.data.email.map(e => ({id: e.id, email: e.email, type: e.pivot?.type_id ?? 1, main: e.pivot?.main ?? false}));
    }
    allowSendOther.value = backPerms.backendPermissions.allowSendFollowUpFromRealAgents ?? false;
    // console.log('page.props', page.props);
    if (page.props.agent && page.props.agent.length && allowSendOther.value ) {
        agentEmails.value.push({
            id: 0,
            email: 'Real Users',
            name: 'Real',
            type: '',
            isDivider: true
        })
        const ags = page.props.agent.map((agent, index) => ({
            id: agent.id,
            email: agent.email,
            name: agent.first_name + ' ' + agent.last_name,
            type: index=== 0 ? 'My Work Email' :'Assigned',
            // isDivider: index === page.props.agent.length-1
            isDivider: false
        }));
        agentEmails.value.push(...ags)
    }
    else {
        agentEmails.value.push({
            id: 0,
            email: 'Real Users',
            name: 'Real',
            type: '',
            isDivider: true
        })
        const ag = {
            id: page.props.auth_user.id,
            email:  page.props.auth_user.work_data.work_email,
            name: page.props.auth_user.work_data.display_name + ' ' +  page.props.auth_user.work_data.display_last_name,
            type: 'My Work Email',
            // isDivider: index === page.props.agent.length-1
            isDivider: false
        }
        agentEmails.value.push(ag)
    }

    // allowSendOther.value = false;
    allowSendFromSystemUsers.value = backPerms.backendPermissions.allowSendFollowUpFromSystemAgents ?? false;
    if (allowSendFromSystemUsers.value && page.props.agents_to_select) {
        agentEmails.value.push({
            id: 0,
            email: 'System Users',
            name: 'System',
            type: '',
            isDivider: true
        })
        const systemAgents = page.props.agents_to_select
            .filter(agent => agent.user_type === "System")
            .map((agent, index) => ({
                id: agent.id,
                email: agent.email,
                name: agent.name,
                type: agent.name,
                isDivider: false
            }));
        agentEmails.value.push(...systemAgents); // добавляем агентов с user_type: "System"
    }
});

function formatFollowUpEmails(followUpEmails) {
    return followUpEmails.map(email => {
        const date = new Date(email.created_at);

        // Форматируем дату
        const formattedDate = date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit'
        });
        const formattedTime = date.toLocaleTimeString('en-GB', {
            hour: '2-digit',
            minute: '2-digit'
        });

        // Возвращаем форматированное сообщение как строку
        return `${formattedDate} ${formattedTime} sent by ${email.sent_agent_name}, from ${email.sent_by}`;
    });
}

function formatFollowUpEmailsForOpens(followUpEmails) {
    const now = new Date();

    // Функция для расчёта времени, прошедшего с момента события до текущего момента
    function formatTimeDifference(date) {
        const diff = Math.abs(now - date);
        const minutes = Math.floor(diff / (1000 * 60));
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 0) {
            return `${days}d ago`;
        } else if (hours > 0) {
            const remainingMinutes = minutes % 60;
            return `${hours}h ${remainingMinutes}m ago`;
        } else {
            return `${minutes}m ago`;
        }
    }

    // Функция для расчета времени между отправкой и первым открытием
    function formatTimeDifferenceBetweenDates(startDate, endDate) {
        const diff = Math.abs(endDate - startDate);
        const minutes = Math.floor(diff / (1000 * 60));
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 0) {
            return `${days}d ${hours % 24}h ${minutes % 60}m`;
        } else if (hours > 0) {
            return `${hours}h ${minutes % 60}m`;
        } else {
            return `${minutes}m`;
        }
    }

    // Форматирование каждого письма
    const res = followUpEmails.flatMap(email => {
        const sentDate = new Date(email.created_at);

        // Форматируем дату и время отправки
        const formattedSentDate = sentDate.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit'
        });
        const formattedSentTime = sentDate.toLocaleTimeString('en-GB', {
            hour: '2-digit',
            minute: '2-digit'
        });

        const messages = [];

        // Если есть открытия писем
        if (email.opens && email.opens.length > 0) {
            // Сортировка открытий по дате от старого к новому
            const sortedOpens = email.opens.slice().sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

            // Первое открытие
            const firstOpen = sortedOpens[0];
            const firstOpenDate = new Date(firstOpen.created_at);
            // Рассчитываем разницу во времени между отправкой и первым открытием
            const firstOpenFormattedTime = formatTimeDifferenceBetweenDates(sentDate, firstOpenDate);

            // Проходим по всем открытиям в порядке от старого к новому
            sortedOpens.forEach((open, index) => {
                const openDate = new Date(open.created_at);
                const formattedOpenDate = openDate.toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit'
                });
                const formattedOpenTime = openDate.toLocaleTimeString('en-GB', {
                    hour: '2-digit',
                    minute: '2-digit'
                });

                // Рассчитываем разницу во времени от текущего момента
                const timeAgo = formatTimeDifference(openDate);

                // Добавляем строку с информацией о каждом открытии
                messages.push(`${index + 1}) ${formattedOpenDate}, ${formattedOpenTime} (${timeAgo})`);
            });

            // Добавляем строку о первом открытии с корректным временем
            messages.push(`First open ${firstOpenFormattedTime} after it was sent.`);
        }

        // Добавляем информацию о времени отправки в самом конце
        messages.push(`→ ${formattedSentDate}, ${formattedSentTime} sent by ${email.sent_agent_name} (from ${email.sent_by})`);

        return messages; // Возвращаем массив строк для каждого письма
    });

    return res; // Возвращаем одномерный массив строк
}








function getSvgFillColor(followUpEmails) {
    const currentUser = page.props.auth_user.id; // ID текущего пользователя

    if (!followUpEmails || followUpEmails.length === 0) {
        return '#A96424'; // Цвет по умолчанию, если список пуст
    }

    // Проверяем, есть ли в списке элемент с auth_agent_id равным текущему пользователю
    const userExistsInEmails = followUpEmails.some(email => email.auth_agent_id === currentUser);

    // Возвращаем соответствующий цвет
    if (userExistsInEmails) {
        return '#48752C'; // Если найден текущий пользователь
    } else {
        return '#A96424'; // Если не найден
    }
}


</script>

<template>
    <v-dialog v-model="isDialogActive" width="1200px">
<!--        <template v-slot:activator="{ props: activatorProps }">-->
<!--            <v-btn-->
<!--                v-bind="activatorProps"-->
<!--                color="rgba(75, 85, 99, .8)"-->
<!--                class="d-flex align-center justify-center bg-gray-light"-->
<!--                style="width: 36px; height: 36px; min-width: 36px; padding: 0;"-->
<!--            >-->
<!--                <v-icon icon="mdi-email" color="black" size="20"></v-icon>-->
<!--            </v-btn>-->
<!--        </template>-->

        <template v-slot:default>
            <v-card style="height:900px; overflow: hidden; position: relative; padding: 20px">
                <v-icon
                    icon="mdi-close"
                    @click="isDialogActive = false"
                    color="black"
                    size="24"
                    class="close-icon"
                    style="position: absolute; top: 10px; right: 10px; cursor: pointer;"
                ></v-icon>

                <v-card-title style="font-weight: 700; margin-top: 5px; font-size: 16px; line-height: 22px; color: #4B5563;">
                    Follow-up Templates
                </v-card-title>
                <v-divider></v-divider>

                <div v-if="loading" class="loader-container">
                    <v-progress-circular indeterminate color="primary" size="50"></v-progress-circular>
                </div>

                <v-row v-else>
                    <v-col>
                        <v-card style="height: 100%">
                            <v-card-text style="padding: 0">
                                <!-- Компонент поиска -->
                                <v-text-field
                                    density="compact"
                                    style="margin: 13px;"
                                    variant="outlined"
                                    v-model="search"
                                    label="Search templates"
                                    hide-details
                                    clearable
                                >
                                    <template v-slot:append>
                                        <v-icon>mdi-magnify</v-icon>
                                    </template>
                                </v-text-field>

                                <!-- Вкладки для переключения между reached и not reached -->
                                <v-tabs v-model="activeTab" style="padding: 0 10px 0 10px">
                                    <v-tab width="50%" :style=" activeTab === 'reached' && 'background-color: rgb(238, 238, 238)'" value="reached">
                                        <template v-slot:default>
                                            Reached&nbsp; <span style="color: #009051">
                                            {{ templates.count_sent_reached}}
                                        </span>
                                            /{{templates.count_reached}}
                                        </template>
                                    </v-tab>
                                    <v-tab width="50%" :style=" activeTab === 'not_reached' && 'background-color: rgb(238, 238, 238)'" value="not_reached">
                                        <template v-slot:default>
                                            Not Reached&nbsp; <span style="color: #009051">
                                            {{ templates.count_sent_not_reached}}
                                        </span>
                                            /{{templates.count_not_reached}}
                                        </template>
                                    </v-tab>
                                </v-tabs>

                                <v-divider></v-divider>

                                <!-- Аккордеон по категориям с открытыми панелями по умолчанию -->
                                <v-expansion-panels v-model="openedPanels" style="max-height: 470px; overflow-y: auto; padding: 0 10px 10px 10px">
                                    <v-expansion-panel
                                        v-for="(templates1, category, index) in filteredTemplatesByCategory"
                                        :key="index"
                                        expand-icon=""
                                    >

                                        <v-expansion-panel-title v-if="activeTab === 'reached'" class="expansion-title"
                                                                 :style="{ backgroundColor: openedPanels === index ? 'rgb(238, 238, 238)' : '' }"

                                        >
                                            <div>{{ category }}</div>
                                            <div class="right-align">
                                                <span style="color: #009051">{{ templates.reached_categories?.[category]?.sent_count || 0 }}</span>
                                                /&nbsp;{{ templates.reached_categories?.[category]?.count || 0 }}
                                            </div>
                                        </v-expansion-panel-title>

                                        <v-expansion-panel-title v-if="activeTab === 'not_reached'" class="expansion-title"
                                                                 :style="{ backgroundColor: openedPanels === index ? 'rgb(238, 238, 238)' : '' }">
                                            <div>{{ category }}</div>
                                            <div class="right-align">
                                                <span style="color: #009051">{{ templates.not_reached_categories?.[category]?.sent_count || 0 }}</span>
                                                /&nbsp;{{ templates.not_reached_categories?.[category]?.count || 0 }}
                                            </div>
                                        </v-expansion-panel-title>



                                        <v-expansion-panel-text style="padding: 0">
                                            <v-list density="compact" variant="plain" style="padding: 0;">
                                                <v-list-item
                                                    v-for="(template, index) in templates1"
                                                    :key="index"
                                                    style="cursor: pointer; padding: 0"
                                                    @click="selectTemplate(template)"
                                                >
                                                    <v-list-item-title>&nbsp;{{ template.short_name }}</v-list-item-title>
                                                    <template v-slot:prepend>
                                                        <template v-if="template.follow_up_emails.length > 0 && !template.is_opened">
                                                            <v-tooltip >
                                                                <template v-slot:activator="{ props }">
                                                                    <div v-bind="props">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" :fill="getSvgFillColor(template.follow_up_emails)">
                                                                            <path d="M480-432 168-611v347h384v72H168q-29.7 0-50.85-21.16Q96-234.32 96-264.04v-432.24Q96-726 117.15-747T168-768h624q29.7 0 50.85 21.15Q864-725.7 864-696v264h-72v-179L480-432Zm0-85 312-179H168l312 179ZM768-84l-51-51 57-57H624v-72h150l-57-57 51-51 144 144L768-84ZM168-611v381-466 85Z"/>
                                                                        </svg>
                                                                    </div>
                                                                </template>
                                                                <div>
                                                                    <ul style="padding: 0; margin: 0; list-style-type: none">
                                                                        <li style="margin-left: 1px; font-family: monospace" v-for="(email, index) in formatFollowUpEmails(template.follow_up_emails)" :key="index">
                                                                            {{ email }}
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </v-tooltip>
                                                        </template>
                                                        <template v-if="template.is_opened">
                                                            <v-tooltip>
                                                                <template v-slot:activator="{ props }">
                                                                    <div v-bind="props">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 928 928" :fill="getSvgFillColor(template.follow_up_emails)">
                                                                            <g>
                                                                                <path d="M795.9,470.363l92-62.5c13.5-9.2,14.699-28.601,2.5-39.4l-94.5-83.5V470.363z"/>
                                                                                <path d="M430.9,20.862l-51.9,45.9h170l-51.9-45.9C478.2,4.163,449.8,4.163,430.9,20.862z"/>
                                                                                <path d="M40.2,407.863l92,62.5V284.962l-94.5,83.5C25.4,379.262,26.7,398.762,40.2,407.863z"/>
                                                                                <path d="M640.9,617.963l244.699,236.4c15.9,15.3,42.4,4.1,42.4-18v-366.2c0-20.101-22.5-32-39-20.701L640.9,617.963z"/>
                                                                                <path d="M42.4,854.363l244.7-236.4L39,449.462c-16.6-11.3-39,0.6-39,20.701v366.2C0,858.463,26.5,869.663,42.4,854.363z"/>
                                                                                <path d="M858.3,876.663l-247-238.601L501.9,712.363c-11.5,7.8-24.7,11.699-37.9,11.699s-26.4-3.899-37.9-11.699L316.7,638.062l-247,238.601c-16.2,15.6-5.1,43,17.4,43H841C863.4,919.562,874.5,892.263,858.3,876.663z"/>
                                                                                <path d="M345,96.762H212.1c-27.6,0-50,22.4-50,50v109.1v2.6v232.3l0,0l271.7,188.5c8.9,6.7,19.5,10.101,30.101,10.101c10.6,0,21.199-3.4,30.1-10.101l271.7-188.5v-232.3v-2.6v-109.1c0-27.6-22.4-50-50-50H583h-23.4H368.4H345z M296,436.462c0-8.3,6.7-15,15-15h306c8.3,0,15,6.7,15,15s-6.7,15-15,15H311C302.7,451.462,296,444.762,296,436.462z M617,283.962c8.3,0,15,6.7,15,15s-6.7,15-15,15H311c-8.3,0-15-6.7-15-15s6.7-15,15-15H617z"/>
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                </template>
                                                                <div>
                                                                    <ul style="padding: 0; margin: 0; list-style-type: none">
                                                                        <li style="margin-left: 1px; font-family: monospace" v-for="(email, index) in formatFollowUpEmailsForOpens(template.follow_up_emails)" :key="index">
                                                                            {{ email }}
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </v-tooltip>
                                                        </template>
                                                    </template>
                                                    <template v-slot:append>
                                                        <v-tooltip :text="template.description ?? 'No Description'">
                                                            <template v-slot:activator="{ props }">
                                                                <div v-bind="props">
                                                                <v-icon  color="primary">
                                                                    mdi-help-circle-outline
                                                                </v-icon>
                                                                </div>
                                                            </template>
                                                        </v-tooltip>
                                                    </template>
                                                </v-list-item>
                                            </v-list>
                                        </v-expansion-panel-text>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-divider vertical></v-divider>

                    <v-col cols="8">
                        <v-card style="height: 100%">
                            <v-card-text>
                                <v-select
                                    density="compact"
                                    v-if="allowSendOther || allowSendFromSystemUsers"
                                    variant="outlined"
                                    label="From"
                                    v-model="form.from_email"
                                    :items="agentEmails"
                                    item-title="email"
                                    item-value="email"
                                    :menu-props="{ maxHeight: '200px' }"
                                >
                                    <template #item="{ item, props: { onClick } }" >
                                        <v-list-item :disabled="item.raw.isDivider" :title="item.title" @click="onClick" :style="item.raw.isDivider ? 'background-color: #EEEEEF; font-size: 25px': ''">
                                            <template v-slot:append>
                                                {{item.raw.type}}
                                            </template>
                                        </v-list-item>
                                    </template>
                                </v-select>

                                <v-select
                                    variant="outlined"
                                    density="compact"
                                    label="Client email"
                                    v-model="form.email"
                                    :items="emails"
                                    item-title="email"
                                    item-value="id"
                                >
                                    <template #item="{ item, props: { onClick } }" >
                                        <v-list-item :title="item.title" @click="onClick">
                                            <template v-slot:append>
                                                {{item.raw.type === 1 ? 'Personal' : 'Shared'}} {{item.raw.main ? " Main" : ''}}
                                            </template>
                                        </v-list-item>
                                    </template>
                                </v-select>


                                <v-text-field
                                    density="compact"
                                    label="Subject"
                                    variant="outlined"
                                    v-model="form.subject"
                                ></v-text-field>

                                <Tiptap v-model="form.email_body"/>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-card-actions style="margin-top: 10px">
                    <v-spacer />
                    <v-btn
                        color="white"
                        style="background-color: #4c78dd"
                        :disabled="!form.email_body || !form.email || !form.subject"
                        @click="sendEmail"
                    >
                        Send Email
                    </v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>

<style scoped>
.loader-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.expansion-title {
    position: relative; /* Чтобы можно было использовать absolute для вложенных элементов */
}

.right-align {
    position: absolute;
    right: 13px; /* Отодвигаем от правого края, оставляя место для стрелки */
    top: 50%;
    transform: translateY(-50%); /* Центрируем по вертикали */
    white-space: nowrap; /* Предотвращает перенос текста */
}
:deep(.v-expansion-panel-text__wrapper) {
    padding: 10px!important;
}
:deep(.v-expansion-panel-title) {
    padding: 16px 10px!important;
}
:deep(.v-tooltip__content) {
    white-space: pre-line;
}
:deep(.v-expansion-panel-title__icon) {
    display: none;
}
</style>
