import {computed, nextTick, onMounted, reactive, watch} from "vue";
import {router, usePage,} from "@inertiajs/vue3";
import {
    getFormattedEmailsFor,
    getFormattedFFPrograms,
    getFormattedPhoneNumbersFor
} from "@services/FormattedDataForServerService";
import {getRoute} from "@plugins/useRoutes";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";
import {getFormattedBestTimeToCall} from "@services/FormattedDataForServerService";
import useFeatureLinkedContactsCreateAndEdit
    from "@features/FeatureLinkedContactsCreateAndEdit/useFeatureLinkedContactsCreateAndEdit";

import initClientEditWatchers from "./watchers/clientEdit.watchers";

import {getStatusBadgeClass} from "./helpers";
import useClientEditForms from "./forms/clientEdit.forms";
import useBackendMiddlewarePropsService from "@services/BackendMiddlewarePropsService";
import {cloneDeep} from "lodash";

export default function (params = {}) {
    const {props} = params
    const AlertsManager = useAlertsManagerStore()
    const $page = usePage();

    const {backendOptions} = useBackendMiddlewarePropsService()

    const locState = reactive({
        activeTab: 'client-info', // linked-contacts   client-info
        nextTab: null,
        needToSaveForms: [],
        savedForms: [],
        disableSave:false,
    });


    const getClientId = computed(() => props.resource?.data?.id)

    const {clientInfoForm, clientPassportForm, setClientGeneralInfo} = useClientEditForms(props)

    initClientEditWatchers({clientInfoForm, clientPassportForm})

    const formsState = reactive({
        oldPassportFormDataString: JSON.stringify(clientPassportForm.data())
    })
    const alertYourChangesNotBeSaved = function (e) {
        e.preventDefault()
    }

    watch(()=>clientPassportForm.data() , (val)=>{
        const clone = cloneDeep(val)
        const currentPassportFormDataString =   JSON.stringify(clone)
        if(currentPassportFormDataString != formsState.oldPassportFormDataString){
            window.onbeforeunload = alertYourChangesNotBeSaved
        }

        if(currentPassportFormDataString == formsState.oldPassportFormDataString){
            window.onbeforeunload = null
        }
    }, {deep:true})


    const checkCanExit = (exit) => {
        if (exit) {
            const needSave = locState.needToSaveForms.sort().join(',')
            const saved = locState.savedForms.sort().join(',')

            if (needSave === saved) {
                router.get(getRoute('clients'))
            }
        }
    }

    const clientSaveHandle = (exit = false) => {
        const needSaveClient = clientInfoForm.isDirty
        locState.savedForms = []
        locState.needToSaveForms = []

        locState.disableSave = true

        if (needSaveClient) {
            if (!locState.needToSaveForms.includes('client')) {
                locState.needToSaveForms.push('client')
            }

            let passportCallback = () => {}

            if(clientPassportForm.isDirty){
                if (!locState.needToSaveForms.includes('client-passport')) {
                    locState.needToSaveForms.push('client-passport')
                }
                passportCallback = () => clientPassportSaveHandle(exit)
            }

            clientInfoSaveHandle(exit, passportCallback)
        }
        if (!needSaveClient && clientPassportForm.isDirty) {
            if (!locState.needToSaveForms.includes('client-passport')) {
                locState.needToSaveForms.push('client-passport')
            }

            clientPassportSaveHandle(exit)
        }

    }

    const clientInfoSaveHandle = (exit, submitPassportDataHandle) => {
        clientInfoForm.transform(data => {

            const {
                generalInfo,
                contacts,
                locationTimezone,
                ff_programs,
                ...rest
            } = data;

            const {country_id, state_id, city_id, timezone_id} = locationTimezone

            const {best_time_call_from, best_time_call_to} = getFormattedBestTimeToCall(contacts.best_time_to_call)
            return {
                ...generalInfo,
                resource_id: getClientId.value,
                best_time_call_from,
                best_time_call_to,
                emails: getFormattedEmailsFor.backend(contacts.emails),
                phone_numbers: getFormattedPhoneNumbersFor.backend(contacts.phone_numbers),
                ff_programs: getFormattedFFPrograms(ff_programs),
                country_id, state_id, city_id, timezone_id,
                ...rest,
            };

        }).put(getRoute('clients.update', [getClientId.value]), {
            preserveScroll: true,
            preserveState: true,
            onSuccess(response) {
                const existServerError = $page.props.flash?.error

                if (existServerError) {
                    locState.disableSave = false
                    return
                }

                submitPassportDataHandle?.()
                AlertsManager.add('Client general info was updated')
                locState.savedForms.push('client')
                checkCanExit(exit)
                locState.disableSave = false
            },
            onError(e) {
                console.log('error', e);
                const errorMessages = Object.entries(e)
                    .map(([field, message]) => `${message}`)
                    .join('\n')
                AlertsManager.add(errorMessages ?? 'Client general info has errors', 'danger')
                locState.disableSave = false
            }
        });
    };

    const clientPassportSaveHandle = (exit) => {
        if (clientPassportForm.id) {
            clientPassportForm
                .transform(data => {
                    return {
                        ...data,
                        is_main_passenger: true,
                        client_id: getClientId.value,
                    }
                })
                .put(getRoute('passengers.client-update', clientPassportForm.id), {
                    preserveScroll: true,
                    onSuccess() {
                        const existServerError = $page.props.flash?.error

                        if (existServerError) {
                            locState.disableSave = false
                            return
                        }

                        AlertsManager.add('Client passport successfully updated',)
                        locState.savedForms.push('client-passport')
                        locState.disableSave = false
                        formsState.oldPassportFormDataString = JSON.stringify(clientPassportForm.data())
                        checkCanExit(exit)

                    },

                    onError() {
                        locState.disableSave = false
                        AlertsManager.add('Client passport has errors', 'danger')
                    }
                })
        } else {
            clientPassportForm
                .transform(data => {
                    return {
                        ...data,
                        is_main_passenger: true,
                        client_id: getClientId.value,
                    }
                }).post(getRoute('passengers.client-store'), {
                preserveScroll: true,

                onSuccess(response) {
                    const existServerError = $page.props.flash?.error
                    if (existServerError) {
                        locState.disableSave = false
                        return
                    }

                    clientPassportForm.id = $page.props.flash.success?.passenger?.data.id

                    AlertsManager.add('Client passport was created')
                    locState.disableSave = false
                    checkCanExit(exit)
                    formsState.oldPassportFormDataString = JSON.stringify(clientPassportForm.data())
                },

                onError() {
                    locState.disableSave = false
                    AlertsManager.add('Client passport has errors', 'danger')
                }
            })
        }
    }

    const tabPassengers = reactive({
        formCreateShow: false,
        modalAlert: false,
        formEditShow: false,
        formEditData: null,
    });
    const tabRequests = reactive({
        formCreateShow: false,
    });

    const {linkedContactsHandlers: tabLinkedContactsHandlers, linkedContactsState: tabLinkedContacts} = useFeatureLinkedContactsCreateAndEdit()

    const passengersHandlers = {
        cancel() {
            tabPassengers.modalAlert = false;
            tabPassengers.formCreateShow = false;
            tabPassengers.formEditShow = false;
            tabPassengers.formEditData = null
            goNextSelectedTab()
        },
        edit(object) {
            tabPassengers.formEditData = object
            tabPassengers.formEditShow = true
        },
    }

    const nextTabActivate = (tabKey) => {
        locState.activeTab = tabKey;
    }
    const onChangeTabHandle = (tabKey) => {
        locState.nextTab = tabKey
        if (tabPassengers.formCreateShow || tabPassengers.formEditShow) {
            if (locState.activeTab != tabKey) {
                tabPassengers.modalAlert = true;
            }
            return;
        }
        if (tabRequests.formCreateShow) {
            if (locState.activeTab != tabKey) {
                tabRequests.modalAlert = true;
            }
            return;
        }
        if (tabLinkedContacts.formCreateShow || tabLinkedContacts.formEditShow) {
            if (locState.activeTab != tabKey) {
                tabLinkedContacts.modalAlert = true;
            }
            return;
        }

        nextTabActivate(tabKey);
    };

    const goNextSelectedTab = () => {
        if (locState.nextTab && locState.nextTab != locState.activeTab) {
            locState.activeTab = locState.nextTab
            locState.nextTab = null
        }
    }

    const isCreateOrEditPassenger = computed(() =>
        tabPassengers.formCreateShow || tabPassengers.formEditShow
    )
    const isCreateOrEditLinkedContact = computed(() =>
        tabLinkedContacts.formCreateShow || tabLinkedContacts.formEditShow)

    const canShowBottomButtonsForClient = computed(() => {
        return locState.activeTab == 'client-info' && (clientInfoForm.isDirty || clientPassportForm.isDirty)
    })

    const canShowHeaderAddButton = computed(() => {
        const {activeTab} = locState
        return {
            passengers: activeTab == 'passengers' && !tabPassengers.formCreateShow && !tabPassengers.formEditShow,
            request: activeTab == 'requests' && !tabRequests.formCreateShow,
            linkedContacts: activeTab == 'linked-contacts' && !tabLinkedContacts.formEditShow && !tabLinkedContacts.formCreateShow
        }
    })

    const getDataForClientOverview = computed(() => {
        const {created_at, updated_at} = props.resource?.data

        const getBestTimeToCall = () => {
            const {best_time_to_call: {from, to}} = props.contacts
            if (from && to) {
                return `${from.time} ${from.type} - ${to.time} ${to.type}`
            }
            return null
        }

        const {gender_id, contact_by_id} = clientInfoForm.generalInfo
        const {emails, phone_numbers} =  props.contacts
        return {
            generalInfo: setClientGeneralInfo?.(),
            contacts: {
                emails: emails?.data ?? null,
                phone_numbers: phone_numbers?.data ?? null,
            },
            genderAndContactBy: {
                gender: backendOptions.genderOptions.find(item => item.id == gender_id)?.title,
                contactBy: backendOptions.contactSocialOptions.find(item => item.id == contact_by_id)?.title,
            },
            changesDates: {
                created: created_at,
                updated: updated_at,
            },
            bestTimeToCall: getBestTimeToCall()
        }
    })

    const getPropsForBoxClientOverview = computed(() => {
        const {createdAndUpdated, resource} = props
        const {
            generalInfo,
            genderAndContactBy,
            bestTimeToCall,
            changesDates
        } = getDataForClientOverview.value
        return {
            generalInfo, genderAndContactBy, bestTimeToCall, changesDates,
            contacts: clientInfoForm.contacts,
            createdAndUpdated: createdAndUpdated,
            updatedData: resource,
        }
    })

    onMounted(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if(urlParams.has('createNewRequest')){
            locState.activeTab = 'requests'
            tabRequests.formCreateShow = true
            nextTick( () => {
                history.replaceState(null, null, getRoute('clients.edit', {id:getClientId.value}));
            });
        }
    });
    return {
        clientInfoForm, clientPassportForm, getClientId,

        locState, tabRequests, tabPassengers,

        tabLinkedContacts, tabLinkedContactsHandlers,

        getPropsForBoxClientOverview,

        clientSaveHandle,

        getStatusBadgeClass,

        onChangeTabHandle,

        passengersHandlers,

        isCreateOrEditPassenger, isCreateOrEditLinkedContact, canShowHeaderAddButton, canShowBottomButtonsForClient,
    }
}
