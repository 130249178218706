<template>
    <acms-v-form-field
        label="Last name"
        name="last_name"
        :class="$attrs.class ?? 'col-3'"
        placeholder="ex. Doe"
        :modeInput="{capitalizeFirstCharacter:false}"
        :model-value="modelValue"
        @update:modelValue="$emit('update:modelValue', $event)"
        :errors="errors"
        :required="required"
    />
</template>

<script>
export default {
    name: "FieldLastName"
}
</script>
<script setup="">
const props = defineProps({
    modelValue:[String],
    errors:Object,
    required:Boolean
})
</script>
<style scoped>

</style>
