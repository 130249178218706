<template>
    <div class="acms-v-table" :class="[{ 'acms-v-table--can-drag-table': tableDrag.canDrag }, { 'acms-v-table--empty': locState.initDataLength == 0 && !search }]" ref="clientsTable">
        <template v-if="!getTableDataIsEmpty">
            <template v-if="$slots?.header">
                <slot name="header"></slot>
            </template>
            <div v-if="$slots?.['header-content']" class="acms-v-table-header">
                <slot name="header-content"></slot>
            </div>
        </template>
        <template v-if="!getTableDataIsEmpty">
            <div class="acms-v-table-content table-responsive" :class="contentClass" v-on="tableContentEvents" ref="tableContentElRef">
                <table class="table table-striped table-hover table-vcenter" :class="tableClass">
                    <thead v-if="!hideHead">
                    <tr>
                        <template v-for="column in getColumns">
                            <th :class="column.class" :style="{ 'width': column.width }">
                                <acms-v-table-column-title
                                    :sort="sortState"
                                    @update:sort="(e) => sortHandle(e, column.key.replaceAll('-', '_'))"
                                    :isSortable="canSortColumn(column.key)"
                                    :class="getColumnTitleClass(column)"
                                    :columnKey="column.key.replaceAll('-', '_')"
                                >
                                    <template v-if="!$slots['headCell-' + column.key]">
                                        {{ column.title }}
                                    </template>
                                    <slot :key="column.key" :title="column.title" :name="'headCell-' + column.key"></slot>
                                </acms-v-table-column-title>
                            </th>
                        </template>
                        <th class="text-center" style="width: 52px;">
                            <input type="checkbox" @change="toggleSelectAll" />
                        </th>
                    </tr>
                    </thead>
                    <tbody class="acms-v-table-body">
                    <template v-for="(rowItem, rowIndex) in getData">
                        <tr
                            v-on="getTableEventsByKey('row', { rowItem, rowIndex })"
                            :class="[bodyRowClass, dynamicRowClass?.(rowItem), getRowUnpublishedClass(rowItem), { 'selected': isSelected(rowItem) }]"
                        >
                            <td v-for="(column, columnIndex) in getColumns" :style="{ 'width': column.width }">
                                <div class="acms-v-table-body-cell d-flex" :class="column.cellClass">
                                    <template v-if="rowItem?.parent_id && column.key == 'id'">
                                        <div class="table-cell-ico-child"></div>
                                    </template>
                                    <template v-if="column.key == 'actions' && !$slots['bodyCell-actions']">
                                        <acms-v-table-actions
                                            @clickAction="$emit($event + 'Action', rowItem)"
                                            :actions="actionsShowFun?.(rowItem) ?? actions"
                                            :published="rowItem?.[keyForPublished]"
                                        />
                                    </template>
                                    <template v-else-if="!$slots['bodyCell-' + column.key.replaceAll('_', '-')]">
                                        {{ rowItem?.[column.key?.replaceAll('-', '_') ?? column.key] }}
                                    </template>
                                    <slot :columnKey="column.key" :index="rowIndex" :item="rowItem" :name="'bodyCell-' + column.key.replaceAll('_', '-')"></slot>
                                </div>
                            </td>
                            <td class="text-center" style="width: 52px;">
                                <input type="checkbox" v-model="selectedRows" :value="rowItem" @click.stop />
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </table>
            </div>
            <template v-if="search && getData?.length == 0">
                <div class="alert alert-info w-100 text-center mb-0">
                    <p class="mb-0 fw-medium">No results</p>
                </div>
            </template>
            <div class="acms-v-table-footer">
                <slot name="footer"></slot>
                <AcmsVTablePagination
                    v-if="links?.length > 3"
                    :links="links"
                    :page="page"
                    @update:page="$emit('update:page', $event)"
                />
            </div>
        </template>
        <template v-else>
            <div class="alert alert-info w-100 text-center mb-0">
                <p class="mb-0 fw-medium">{{ emptyStateTitle ?? 'Table' }} with no additional information added yet.</p>
            </div>
        </template>
    </div>
</template>

<script setup>
import { ref, computed, reactive, onBeforeMount, watch } from 'vue';
import { cloneDeep } from 'lodash';
import AcmsVTableColumnTitle from './partials/TableColumnTitle.vue';
import AcmsVTablePagination from "./partials/TablePagination.vue";
import AcmsVTableActions from './partials/TableActions.vue';
import tableProps from "./table.props";

const emit = defineEmits(['update:sort', 'clickRow', 'editAction', 'deleteAction', 'publishAction', 'update:page', 'update:selectedRows']);
const props = defineProps({ ...tableProps });
const locState = reactive({ initDataLength: 0 });
const selectedRows = ref([]);

const getSortColumns = computed(() => {
    let columns = cloneDeep(props.columns);
    if (columns?.actions) delete columns.actions;
    return columns;
});

const getTableEventsByKey = (key, params) => {
    if (key == 'row' && props.eventsList?.includes('row')) {
        return { click(e) { emit('clickRow', params); } };
    }
    return {};
};

const getRowNumber = (rowIndex) => {
    const { entriesLimit, currentPage, page } = props;
    const rowNumber = Number(rowIndex) + 1;

    const calculateRow = (limit, page) => {
        const current = (Number(limit) * Number(page - 1));
        return current + Number(rowNumber);
    };

    if (entriesLimit && currentPage) {
        if (currentPage > 1) {
            return calculateRow(entriesLimit, currentPage);
        }
    }

    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('limit') && searchParams.has('page')) {
        const page = searchParams.get('page');
        const limit = searchParams.get('limit');

        if (Number(page) > 1) {
            return calculateRow(limit, page);
        }
    }
    return rowNumber;
};

const getColumns = computed(() => {
    const { columns, columnsClass, columnsWidth, columnsCellClass, columnsTitleClass } = props;
    return Object.entries(columns).map(([key, title]) => ({
        key,
        title,
        class: columnsClass?.[key] ?? '',
        width: columnsWidth?.[key] ?? '',
        titleClass: columnsTitleClass?.[key] ?? '',
        cellClass: columnsCellClass?.[key] ?? ''
    }));
});

const getData = computed(() => {
    if (props.data) {
        return !Array.isArray(props.data) ? [props.data] : props.data;
    }
    return [];
});

const getTableDataIsEmpty = computed(() => {
    return getData.value?.length == 0 && locState.initDataLength == 0 && !props.search;
});

const tableContentElRef = ref();
const sortState = ref({ column: '', direction: '' });

const sortHandle = (sortDirection, key) => {
    sortState.value = { column: sortDirection != '' ? key : '', direction: sortDirection };
    emit('update:sort', sortState.value);
};

const canSortColumn = (key) => {
    const sortKeys = props.sortKeys;
    if (typeof sortKeys == 'string' || sortKeys) {
        if (sortKeys?.length > 1) {
            return sortKeys?.includes(key);
        } else {
            return Object.keys(getSortColumns.value).includes(key);
        }
    }
};

const getRowUnpublishedClass = (rowItem) => {
    return { 'acms-v-table--row-unpublished': typeof rowItem?.[props.keyForPublished] !== 'undefined' && !rowItem[props.keyForPublished] };
};

const getColumnTitleClass = (column) => {
    const { key, titleClass } = column;
    if (key == 'actions') {
        return titleClass != '' ? titleClass : 'justify-content-center';
    }
    return titleClass;
};

let mousedownTime;

const tableDrag = reactive({
    canDrag: false,
    posX: null,
    posLeft: null,
    scrollLeft: null
});

const mousedownHandle = (e) => {
    clearTimeout(mousedownTime);
    mousedownTime = setTimeout(() => {
        if (window.getSelection().type == 'Caret') {
            tableDrag.canDrag = true;
            tableDrag.posX = e.clientX;
            tableDrag.posLeft = e.target.scrollLeft;
            tableDrag.scrollLeft = tableContentElRef.value.scrollLeft;
        }
    }, 400);
};

const cancelTableDrag = () => {
    tableDrag.scrollLeft = null;
    clearTimeout(mousedownTime);
    tableDrag.canDrag = false;
};

const mouseupHandle = (e) => {
    cancelTableDrag();
};

const mouseleaveHandle = () => {
    cancelTableDrag();
};

const mousemoveHandle = (e) => {
    if (tableDrag.canDrag) {
        const dx = tableDrag.posX - e.clientX;
        if (tableDrag.scrollLeft > 0) {
            tableContentElRef.value.scrollLeft = tableDrag.scrollLeft - (e.clientX - tableDrag.posX);
        } else {
            tableContentElRef.value.scrollLeft = dx - tableDrag.posLeft;
        }
    }
};

const scrollHandle = (e) => {
    const tableEl = tableContentElRef.value;
    if (tableEl.scrollWidth > tableEl.clientWidth) {
        e.preventDefault();
        tableContentElRef.value.scrollLeft = tableEl.scrollLeft + e.deltaY;
    }
};

const tableContentEvents = {
    mousedown: mousedownHandle,
    mouseup: mouseupHandle,
    mouseleave: mouseleaveHandle,
    mousemove: mousemoveHandle,
    wheel: props.horizontalScroll ? scrollHandle : () => { }
};

onBeforeMount(() => {
    const data = !Array.isArray(props.data) ? [props.data] : props.data;
    locState.initDataLength = data?.length ?? 0;
});

const toggleSelectAll = (event) => {
    if (event.target.checked) {
        selectedRows.value = getData.value.map(item => item);
    } else {
        selectedRows.value = [];
    }
    emit('update:selectedRows', selectedRows.value);
};

const toggleRowSelection = (rowItem) => {
    const index = selectedRows.value.findIndex(row => row.id === rowItem.id);
    if (index === -1) {
        selectedRows.value.push(rowItem);
    } else {
        selectedRows.value.splice(index, 1);
    }
    emit('update:selectedRows', selectedRows.value);
};

const isSelected = (rowItem) => {
    return selectedRows.value.some(row => row.id === rowItem.id);
};

// Watch for changes in selectedRows and emit event
watch(selectedRows, (newSelectedRows) => {
    emit('update:selectedRows', newSelectedRows);
});
</script>

<style lang="scss">
@import "@scssVars";

table thead th {
    color: #6C757D;
}

.table {
    margin-bottom: 0;
}

table td {
    font-size: 14px;
    line-height: 20px;
}

.acms-v-table {
    background-color: #fff;
    border-radius: 5px;

    &.acms-v-table--can-drag-table {
        cursor: grabbing;
        user-select: none;
    }

    &.acms-v-table--empty {
        padding-top: 64px !important;
        padding-bottom: 64px !important;
    }
}

.acms-v-table--row-unpublished {
    background-color: $bg-danger-light;

    td {
        box-shadow: unset;
    }
}

.acms-v-table-content {
    min-height: 53px;
}

tr.selected {
    background-color: #cce5ff;
}
</style>
