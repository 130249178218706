<template>
    <div class="acms-v-datepicker"
         ref="elementRef"
         :class="[{'acms-v-datepicker--range': datesRange},{'opened': locState.opened}]"
    >
        <VueDatePicker
            v-model="locState.value"
            ref="dp"
            v-bind="getProps"
            :format="format"
            :placeholder="placeholder"
            :inline="inline"
            :enable-time-picker="timePicker"
            @update:model-value="$emit('submitDate')"
            @open="openHandle"
            @closed="locState.opened = false"
        >
            <template #trigger v-if="$slots?.trigger">
                <slot name="trigger"></slot>
            </template>
            <template #calendar-header="{ index, day }">
                {{ weekDayNames[index] }}
            </template>
            <template #action-row="{ closePicker, selectDate }">
                <div class="action-row d-flex w-100">
                    <button class="close-button ms-auto"
                            @click="()=> [selectDate(), closePicker()]">
                        {{ submitText }}
                    </button>
                </div>
            </template>
        </VueDatePicker>
    </div>

</template>

<script>
export default {
    name: 'AcmsVDatepicker',
};
</script>

<script setup>
import {computed, onMounted, reactive, ref, watch, getCurrentInstance} from 'vue';
import {defaultAttributesProps} from '../field.props';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

const emit = defineEmits(['update:modelValue', 'submitDate']);

const weekDayNames = {
    0: 'Mon',
    1: 'Tue',
    2: 'Wed',
    3: 'Thu',
    4: 'Fri',
    5: 'Sat',
    6: 'Sun',
};

const format = (date) => {
    let result = '';
    let dates = Array.isArray(date) ? date : [date];

    if (dates.length > 0) {
        // Отсортируем даты, чтобы гарантировать, что мы всегда берем первую из одинаковых дат
        dates.sort((a, b) => a - b);

        // Используем Set для хранения уникальных отформатированных дат
        const uniqueDates = new Set();

        dates.forEach(item => {
            if (item) {
                const day = item.getDate();
                const month = item.getMonth() + 1;
                const year = item.getFullYear();
                const tinyYear = year.toString().substring(2,4);
                let dateString = `${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}/${tinyYear}`;

                // Добавляем отформатированную дату в Set
                uniqueDates.add(dateString);
            }
        });

        // Преобразуем Set обратно в массив и соединяем элементы
        result = Array.from(uniqueDates).join(' - ');
    }

    return result;
};

const props = defineProps({
    ...defaultAttributesProps,
    datesRange: Boolean,
    modelValue: [String, Number, Array, Date],
    inline: Boolean,
    submitText: {
        type: String,
        default: 'Done'
    },
    format: {
        type: String,
        default: 'MM/dd/yyyy'
    },

    timePicker: Boolean,
    minDateCurrent: Boolean,
    maxDateCurrent: Boolean,
    minDate: {
        type: [String, Date],
        default: null,
    },
    maxDate: {
        type: String,
        default: null,
    },
    startDate: {
        type: [String, Date],
        default: null,
    },
    dayClass: Function,

});

const dp = ref();
const elementRef = ref()
const locState = reactive({
    value: new Date(),
    opened: false,
    globalSettings: null
});

const getProps = computed(() => {
    const {datesRange, dayClass, minDateCurrent, minDate, maxDate, maxDateCurrent, format, startDate} = props;
    return {
        dayClass,
        'multi-calendars': datesRange,
        'range': datesRange,
        'min-date': minDate ? minDate : minDateCurrent ? new Date() : undefined,
        'max-date': maxDate ? maxDate : maxDateCurrent ? new Date() : undefined,
        'format' : locState.globalSettings?.format ?? format,
        startDate,
    };
});

watch(() => props.modelValue, (val) => {
    setTimeout(() => {
        if (props.datesRange) {
            locState.value = val ?? ['', '']
        } else {
            locState.value = val
        }
    }, 0);

}, {deep: true});


watch(() => locState.value, (val) => {
    // console.log('valstart', val)
    // if (Array.isArray(val) && val.length === 2) {
    //     const [startDate, endDate] = val;
    //
    //     // Проверяем, что обновляемое значение отличается от текущего
    //     if (startDate && endDate && startDate.getTime() === endDate.getTime() && locState.value[0] && locState.value[1]) {
    //         console.log('locState.value[0]', locState.value[0]);
    //         locState.value = [startDate, ''];
    //         // Эмитим одну дату
    //         emit('update:modelValue', locState.value);
    //     }
    // } else {
    //     console.log('val', val)log
        emit('update:modelValue', val); // Одиночная дата или null
    // }
});


const customClickEvent = new Event("click", {bubbles: true});

const openHandle = () => {
    locState.opened = true
    elementRef.value?.dispatchEvent(customClickEvent);
}

onMounted(() => {
    const app = getCurrentInstance()
    if (app.appContext.config.globalProperties?._uiSettings?.datepicker) {
        locState.globalSettings = app.appContext.config.globalProperties._uiSettings.datepicker
    }

    if (props.datesRange) {
        if (props.modelValue) {
            if (Array.isArray(props.modelValue)) {
                locState.value = []
                props.modelValue.forEach(item => {
                    locState.value.push(item ? item : '')
                })
            }
        } else {
            const startDate = new Date();
            const endDate = new Date(new Date().setDate(startDate.getDate() + 7));
            locState.value = Array.isArray(props.modelValue) ? props.modelValue : ['', ''];
        }

    } else {
        locState.value = props.modelValue ?? null;
    }
});
</script>
<style lang="scss">
$blue: #4C78DD;
$calendar-icon: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.45601 3.12504H12.5453V1.69275C12.5453 1.2183 12.9235 0.833374 13.3935 0.833374C13.8636 0.833374 14.2417 1.2183 14.2417 1.69275V3.12504H15.6554C16.903 3.12504 17.9173 4.15092 17.9173 5.41671V16.875C17.9173 18.139 16.903 19.1667 15.6554 19.1667H4.34589C3.09654 19.1667 2.08398 18.139 2.08398 16.875V5.41671C2.08398 4.15092 3.09654 3.12504 4.34589 3.12504H5.75958V1.69275C5.75958 1.2183 6.13774 0.833374 6.60779 0.833374C7.07785 0.833374 7.45601 1.2183 7.45601 1.69275V3.12504ZM3.78041 16.875C3.78041 17.1901 4.03346 17.448 4.34589 17.448H15.6554C15.9664 17.448 16.2209 17.1901 16.2209 16.875V7.70837H3.78041V16.875Z' fill='%234B5563' fill-opacity='0.8'/%3E%3C/svg%3E%0A");
.acms-v-datepicker {
    & > * {
        font-family: 'Inter', sans-serif;
    }
    .dp__btn, button {
        font-family: 'Inter', sans-serif;
    }
    .dp__arrow_top {
        display: none;
    }
    .dp__calendar {
        & > [aria-label="Calendar wrapper"] {
            display: flex;
            flex-direction: column;
        }
    }
    .dp__input {
        padding: 8px 12px;
        height: 38px;
        font-size: 16px;
        line-height: 22px;
        background-color: transparent;
        border-radius: 4px !important;
    }
    .dp__menu_inner {
        padding-bottom: 0;
    }
    .dp__month_year_select {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #000;
    }
    .dp__input_icon {
        right: 0;
        left: unset;
        background-image: $calendar-icon;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        color: transparent;
        background-position: center;
    }
    .dp__cell_inner {
        font-size: 14px;
        line-height: 22px;
        padding: 6px;
        width: 36px;
        height: 36px;
    }
    .dp__today {
        border: 0;
        color: $blue;
        font-weight: 700;
        font-size: 16px;
        &.dp__active_date, &.dp__range_start {
            color: #fff;
        }
    }
    .dp__range_start, .dp__range_end {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            inset: 0;
            background-color: #f3f3f3;
            z-index: -1;
        }
    }
    .dp__range_start {
        border-radius: 50%;

        &:after {
            border-radius: 50% 0 0 50%;
            right: -1px;
        }
    }
    .dp__range_end {
        border-radius: 50%;
        &:after {
            border-radius: 0 50% 50% 0;
            left: -1px;
        }
    }
    .dp__calendar_row {
        padding-right: 12px;
        padding-left: 12px;
    }
    .dp__month_year_row {
        border-bottom: 1px solid #E9ECEF;
    }
    .dp__calendar_header {
        padding: 0 12px;
    }
    .dp__calendar_header_item {
        color: #6C757D;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        justify-content: center;
        align-items: center;
        display: flex;
        padding: 12px;
        height: unset;
    }
    .dp__date_hover_end:hover, .dp__date_hover_start:hover, .dp__date_hover:hover {
        border-radius: 50%;
    }
    .dp__active_date {
        border-radius: 50%;
        background-color: $blue;
    }
    .dp__action_row {
        padding-top: 0;
    }
    .close-button {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        background-color: $blue;
        border-radius: 4px;
        color: #fff;
        border: none;
        padding: 5px 8px;
    }
    .dp__calendar {
        .dp__calendar_header_separator {
            display: none;
        }
    }
    .dp__clear_icon {
        display: none;
    }
}
.acms-v-datepicker--range {
    min-width: 230px;
}
</style>
