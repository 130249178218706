<template>
    <acms-v-btn style="min-width: 69px" title="Add PQ" class="btn-primary btn-sm me-2" @click="()=>showCommonPQModal()"/>
</template>

<script>
export default {
    name: "ElementDropdownAddPq"
}
</script>

<script setup="">
import {usePageRequestShowStore} from "@store/pages/page-request-edit";
const props = defineProps({})

const pageRequestShowStore = usePageRequestShowStore()
const {
    showCommonPQModal
} = pageRequestShowStore




</script>
<style scoped>

</style>
