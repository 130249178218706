import {computed, onBeforeMount, onMounted, onUnmounted, reactive, watch} from "vue";
import {useForm, usePage} from "@inertiajs/vue3";
import {getRoute} from "@plugins/useRoutes";
import {usePageRequestShowStore} from "@store/pages/page-request-edit";
import {storeToRefs} from "pinia";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";
import {
    MODEL_PQ_COMMON_SECOND_STEP_ADDITIONAL_DATA_BAGGAGE,
    MODEL_PQ_COMMON_SECOND_STEP_ADDITIONAL_DATA_OBJECT,
    MODEL_PQ_COMMON_SECOND_STEP_TOTAL_PRICES_KEYS,
    MODEL_PQ_PASSENGERS_TYPES,
    modelCommonPqFirstStep,
    modelCommonPqSecondStep,
} from "@models/priceQuote.model";

import {modelCommonPqSetInitialDataForObject, modelCommonPqSetPriceObjectData,} from "@models/priceQuote.modelHelpers";
import {cloneDeep, forEach, orderBy, uniqBy} from "lodash";
import useBackendMiddlewarePropsService from "@services/BackendMiddlewarePropsService";
import createCommonPqValidate
    from "@pages/requests/partials/PartialPriceQuotes/modals/ModalCreateCommonPq/createCommonPq.validate";
import numberHelpers from "@helpers/numberHelpers";
import debounce from "@libs/debounce";
import {HttpService} from "@services/HttpService";

function useCreateCommonPq({props, emit}) {
    const pageRequestShowStore = usePageRequestShowStore()
    const {showCommonPQModal} = pageRequestShowStore
    const {commonPQModalShow} = storeToRefs(pageRequestShowStore)
    const AlertsManager = useAlertsManagerStore()
    const backPerms = useBackendMiddlewarePropsService();
    const $page = usePage()
    const {backendOptions} = useBackendMiddlewarePropsService()

    const getPassengers = computed(() => {
        const {adults, children, infants} = props.activeRequest
        const objects = {adults, children, infants}
        return {
            keys: Object.keys(objects),
            objects,
            entries: Object.entries(objects)
        }
    })
    const locState = reactive({
        currentStep: 1,
        pqCodeHasParsed: false,
        pqCodeTableData: null,
        taxesPricesBlocksState: [],
        totalForTaxesPrices: {
            totalNet: 0,
            totalSell: 0,
            totalMarkUp: 0,
        },

        minPricesObjectForSell:{},
        isSubmitting: false,

        duplicateModeShow:false,
        duplicateModeLoading:false,

        draftModeShow:false,
        draftModeLoading:false,
    });

    const getShow = computed(() => {
        if(props.modeDuplicate){
            return locState.duplicateModeShow
        }
        if(props.modeDraft){
            return locState.draftModeShow
        }
        return commonPQModalShow.value
    })

    const forms = reactive({
        firstStep: useForm({}),
        secondStep: useForm(modelCommonPqSecondStep)
    })

    const getTotals = computed(() => {
        const {totalNet, totalSell, totalMarkUp} = locState.totalForTaxesPrices
        const getFixed = (val) => String(val.toFixed(2)).replace('.', ',')
        return {
            totalNet: getFixed(totalNet),
            totalSell: getFixed(totalSell),
            totalMarkUp: getFixed(totalMarkUp)
        }
    })

    const hideModalHandle = () => {
        forms.firstStep.clearErrors()
        forms.firstStep.reset()
        forms.secondStep.clearErrors()
        forms.secondStep.reset()
        locState.currentStep = 1;
        locState.pqCodeHasParsed = false;
        locState.pqCodeTableData = null;

        emit('hide')
        showCommonPQModal?.(false)
    }
    const tryModalHideHandle = ({hideAlertModalHandle}) => {
        hideAlertModalHandle?.()
        hideModalHandle()
    }

    const clearFormSecondStep = () => {
        forms.secondStep.additional_data = {}
        forms.secondStep.additional_data_baggage = {}
        forms.secondStep.sell_prices = null
        forms.secondStep.modeDraft = null
        forms.secondStep.total_net_prices = null
        forms.secondStep.reset()
        forms.secondStep.clearErrors()
    }

    const setFalseForParsedPqStatus = () => {
        locState.pqCodeHasParsed = false
        locState.pqCodeTableData = null
    }

    const parsePqCode = (val) => {
     return HttpService.parsePQCode({code: val}).then(response => {
            const data = response.data.data
             locState.pqCodeHasParsed = true
             locState.pqCodeTableData = data
            clearFormSecondStep()
            return true

        }).catch((e) => {
         const mes =  e.response.data.data.error ?? "Code cannot be parsed";
            setFalseForParsedPqStatus()
            clearFormSecondStep()
            forms.firstStep.setError(`code`, mes);
        })
    }


    const debounceParsePqCode = debounce((val) => {
        parsePqCode(val)
    }, 300)


    const initParseCode = (val) => {
        if (val) {
            setFalseForParsedPqStatus()
         return debounceParsePqCode(val)
        } else {
            setFalseForParsedPqStatus()
        }
    }

    watch(() => forms.firstStep.code, (val) => {
        if(!props.modeDuplicate){
            if (!props.modeDraft) {
                forms.firstStep.clearErrors(`code`);
                initParseCode(val)
            }
        }
    })

    const getPQHasParsed = computed(() => {

        if (locState.pqCodeHasParsed) {
            return {
                hint: 'Pq code has successfully parsed.',
                hintValid: true,
            }
        }
        return {}
    })

    const calculatePriceFromObject = (obj) => {
        let sum = 0
        getPassengers.value.entries
            .forEach(([itemKey, itemValue]) => {
                if (itemValue > 0) {
                    sum = sum + (Number(obj[itemKey]) * Number(itemValue))
                }
            })
        return sum
    }

    const calculateAndSetMarkUp = () => {
        const {totalNet, totalSell} = locState.totalForTaxesPrices
        locState.totalForTaxesPrices.totalMarkUp = totalSell > totalNet ? totalSell - totalNet : 0
    }

    const calculateTotalPrices = (array) => {
        let totalNetObject = {}
        let minValueForSellObject = {}
        getPassengers.value.entries.forEach(([pKey, pValue]) => {
            if (pValue > 0) {
                if (!totalNetObject?.[pKey]) {
                    totalNetObject[pKey] = 0
                }
                if (!minValueForSellObject?.[pKey]) {
                    minValueForSellObject[pKey] = 0
                }
                array.forEach(item => {
                    let value
                    let minValueForSell

                    if (item.type.title == 'Revenue' || item.type.title == 'Online') {
                        value = Number(totalNetObject[pKey]) + Number(item.data[pKey] ?? 0)
                        minValueForSell = Number(minValueForSellObject[pKey]) + Number(item.data[pKey] ?? 0)
                    } else {
                        value = Number(totalNetObject[pKey]) + Number(item.data[pKey]?.net ?? 0)

                        if(item?.mileage?.min_mark_up){
                            minValueForSell = Number(minValueForSellObject[pKey]) + (Number(item.data[pKey]?.net ?? 0) + Number(item.mileage.min_mark_up))
                        }else{
                            minValueForSell = Number(minValueForSellObject[pKey])
                        }
                    }

                    const roundTotalValue = numberHelpers.getRound(value)
                    const roundMinSellValue = numberHelpers.getRound(minValueForSell)
                    totalNetObject[pKey] = roundTotalValue
                    minValueForSellObject[pKey] = roundMinSellValue
                })
            }
        })
        locState.minPricesObjectForSell = minValueForSellObject
        forms.secondStep.total_net_prices = totalNetObject
        locState.totalForTaxesPrices.totalNet = calculatePriceFromObject(totalNetObject)
        calculateAndSetMarkUp()
    }

    const recalculateTotalForSellPrices = () => {
        locState.totalForTaxesPrices.totalSell = calculatePriceFromObject(forms.secondStep.sell_prices)
        calculateAndSetMarkUp()
    }

    watch(commonPQModalShow, (val) => {
        if (val) {
            initModalForms()
        }
    })

    watch(() => forms.secondStep.prices, (array) => {
        calculateTotalPrices(array)
    }, {deep: true})

    const {checkHasOneFilledField, preValidateFirstStep, preValidateSecondStep} = createCommonPqValidate({
        locState,
        forms,
        AlertsManager,
        getPassengers
    })

    const submitSecondStepForm = () => {
        locState.isSubmitting = true
        const transformedData = (data) => {
            const {
                additional_data,
                additional_data_baggage,
                prices,
                remark_ext,
                remark_int,
                sell_prices,
                total_net_prices,
                draftMode,
                is_cfar,
                allow_split,
                updateMode,
                promoFare
            } = data
            const {code} = forms.firstStep.data()

            const formattedAdditionalData = Object.entries(additional_data).map(item => {
                const [itemOrderKey, itemValue] = item
                return {
                    order: itemOrderKey,
                    ...itemValue,
                    baggage_qnt: additional_data_baggage[itemOrderKey].baggage_qnt
                }
            })

            const formattedPrices = cloneDeep(prices).map(item => {
                if (item.type.id == 1 ||  item.type.id == 3) {
                    getPassengers.value.keys.forEach(key => {
                        const existValue = item.data?.[key]
                        if (existValue) {
                            item.data[key] = {'net': existValue}
                        }
                    })
                    return item
                }
                return item
            })

            const object = {
                code,
                additional_flight_legs_data: formattedAdditionalData,
                prices: formattedPrices,
                sell_prices,
                total_net_prices,
                remark_ext,
                remark_int,
                draftMode,
                updateMode,
                is_cfar,
                allow_split,
                promoFare,
                client_id: props.clientId,
                request_id: props.activeRequest.id,

            }

            if(props.modeDuplicate){
                object.duplicate_id = props.duplicateFromPq?.id
            }
            if (props.modeDraft) {
                object.draft_id = props.draftedPq.id
                object.updateMode = props.updateMode;
            }
            return  object
        }
        forms.secondStep.transform(transformedData).post(props.modeDraft ? '/api/pq/update' : getRoute('pq.store'), {
            onSuccess() {
                const existServerError = $page.props.flash?.error

                if (existServerError) {
                    locState.isSubmitting = false
                    return
                }
                locState.isSubmitting = false

                AlertsManager.add('New PQ successfully created!')
                hideModalHandle()
            },
            onError() {
                locState.isSubmitting = false
                AlertsManager.add('PQ has server errors', 'danger')
            },
            onFinish(){
                if (props.modeDraft) {
                    window.location.reload();
                }
            }
        })
    }

    const initSecondStep = () => {

        if (Object.keys(forms.secondStep.additional_data).length == 0) {
            MODEL_PQ_COMMON_SECOND_STEP_TOTAL_PRICES_KEYS.forEach(key => {
                forms.secondStep[key] = modelCommonPqSetInitialDataForObject(getPassengers.value)
            })

            locState.pqCodeTableData?.pq_flight_legs?.forEach(item => {
                const additionalData = {...MODEL_PQ_COMMON_SECOND_STEP_ADDITIONAL_DATA_OBJECT}
                additionalData.type = backendOptions.pqTypesOptions.find(item => item.id == additionalData.type)
                forms.secondStep.additional_data[item.order] = {...additionalData}
                forms.secondStep.additional_data[item.order].initial_cabin_class =  item.flight_details ? item.flight_details[0].cabinType : null;
                forms.secondStep.additional_data_baggage[item.order] = {...MODEL_PQ_COMMON_SECOND_STEP_ADDITIONAL_DATA_BAGGAGE}
            })
        }
        forms.secondStep.draftMode = backPerms.backendPermissions.allowUseDraftMode
        forms.secondStep.updateMode = props.updateMode
        locState.currentStep = 2;
    }


    const navigationControl = {
        next() {
            const isValidated = preValidateFirstStep()
            if (isValidated) {
                initSecondStep()

            }
        },
        back() {
            forms.secondStep?.clearErrors()
            locState.currentStep = 1;
        },
        save() {
            const isValidated = preValidateSecondStep()
            console.log('isValidated', isValidated);
            if (isValidated) {

                submitSecondStepForm()
            }
        }
    }

    const initModalForms = () => {
        forms.firstStep?.clearErrors()
        forms.firstStep = useForm(modelCommonPqFirstStep)
    }

    const generatePricesTaxesItem = (item, oldItem = null) => {
        return modelCommonPqSetPriceObjectData({
            passengers: getPassengers.value, item, oldItem
        });
    }

    const reCollectPricesTaxesBlocks = (dataObject) => {
        forms.secondStep.clearErrors()
        const dataObjects = Object.values(dataObject)
        let clonePrices = cloneDeep(forms.secondStep.prices)
        const newPrices = []
        const uniqTaxesBlocks = uniqBy(dataObjects, (e) => e.ticket);
        locState.taxesPricesBlocksState = cloneDeep(uniqTaxesBlocks)

        uniqTaxesBlocks.forEach((taxPriceItem, index) => {
            const findSameTicket = clonePrices.find(item => item.ticket == taxPriceItem.ticket)
            if (!findSameTicket) {
                newPrices.push(generatePricesTaxesItem(taxPriceItem))
            } else if (findSameTicket) {
                newPrices.push(generatePricesTaxesItem(
                    taxPriceItem,
                    findSameTicket?.type?.id == taxPriceItem?.type?.id
                        ? findSameTicket : null)
                )
            }
        })

        clonePrices = [...newPrices]

        const ticketsArr = locState.taxesPricesBlocksState.map(i => i.ticket)

        forms.secondStep.prices = uniqBy(
            clonePrices.filter(priceBlockItem => ticketsArr.includes(priceBlockItem.ticket)),
            (e) => e.ticket);
        forms.secondStep.prices = orderBy(forms.secondStep.prices, 'ticket', 'asc');
    }

    const initSecondStepForDuplicateMode = () => {
        if (Object.keys(forms.secondStep.additional_data).length == 0) {
            MODEL_PQ_COMMON_SECOND_STEP_TOTAL_PRICES_KEYS.forEach(key => {
                forms.secondStep[key] = modelCommonPqSetInitialDataForObject(getPassengers.value)
            })

            props.duplicateFromPq?.pq_flight_legs?.forEach(item => {
                const additionalData = {...MODEL_PQ_COMMON_SECOND_STEP_ADDITIONAL_DATA_OBJECT}
                additionalData.type =  item.type
                additionalData.ticket =  item.ticket
                additionalData.fare_type = item.fare_type ?? null;
                additionalData.is_real = item.is_real ?? "Real";
                additionalData.mileage =  item.mileage
                additionalData.pcc =  item.pcc
                additionalData.id =  item.id;
                additionalData.initial_cabin_class = item.flight_details[0].cabin_type ?? '';
                additionalData.cabin_class = item.flight_details[0].changed_cabin_class ?? '';
                forms.secondStep.additional_data[item.order] = {...additionalData}
                forms.secondStep.is_cfar =  props.duplicateFromPq.is_cfar;
                forms.secondStep.allow_split =  props.duplicateFromPq.allow_split;
                forms.secondStep.additional_data_baggage[item.order] = {baggage_qnt : item?.baggage }

            })
            locState.currentStep = 2;
        }

    }

    const initSecondStepForDraftMode = () => {
        if (Object.keys(forms.secondStep.additional_data).length === 0) {
            MODEL_PQ_COMMON_SECOND_STEP_TOTAL_PRICES_KEYS.forEach(key => {
                forms.secondStep[key] = modelCommonPqSetInitialDataForObject(getPassengers.value);
            });
            props.draftedPq?.pq_flight_legs?.forEach(item => {
                const additionalData = {...MODEL_PQ_COMMON_SECOND_STEP_ADDITIONAL_DATA_OBJECT};
                additionalData.type =  item.type;
                additionalData.ticket =  item.ticket;
                additionalData.fare_type = item.fare_type ?? null;
                additionalData.is_real = item.is_real ?? "Real";
                additionalData.mileage =  item.mileage;
                additionalData.pcc =  item.pcc;
                additionalData.id =  item.id;
                additionalData.initial_cabin_class = item.flight_details[0].cabin_type ?? '';
                additionalData.cabin_class = item.flight_details[0].changed_cabin_class ?? '';
                // Проверяем наличие additional_data и записываем данные
                if (!forms.secondStep.additional_data) {
                    forms.secondStep.additional_data = {};
                }
                forms.secondStep.additional_data[item.order] = {...additionalData};
                forms.secondStep.is_cfar = props.draftedPq.is_cfar;
                forms.secondStep.allow_split =  props.draftedPq.allow_split;
                // Проверяем наличие additional_data_baggage и записываем данные
                if (!forms.secondStep.additional_data_baggage) {
                    forms.secondStep.additional_data_baggage = {};
                }
                forms.secondStep.additional_data_baggage[item.order] = {baggage_qnt: item?.baggage};
            });
            locState.currentStep = 2;
        }
    };



    // const initSecondStepForDraftMode = () => {
    //     if (Object.keys(forms.secondStep.additional_data).length == 0) {
    //         MODEL_PQ_COMMON_SECOND_STEP_TOTAL_PRICES_KEYS.forEach(key => {
    //             forms.secondStep[key] = modelCommonPqSetInitialDataForObject(getPassengers.value)
    //         })
    //
    //         props.draftedPq?.pq_flight_legs?.forEach(item => {
    //             const additionalData = {...MODEL_PQ_COMMON_SECOND_STEP_ADDITIONAL_DATA_OBJECT}
    //             additionalData.type =  item.type
    //             additionalData.ticket =  item.ticket ?? 1
    //             additionalData.fare_type = item.fare_type ?? null;
    //             additionalData.is_real = item.is_real ?? "Real";
    //             additionalData.mileage =  item.mileage
    //             additionalData.pcc =  item.pcc
    //             additionalData.id = item.id
    //
    //             console.log('item',additionalData, item.id);
    //             forms.secondStep.additional_data[item.order] = {...additionalData}
    //             forms.secondStep.additional_data_baggage[item.order] = {baggage_qnt : item?.baggage }
    //         //
    //         })
    //         console.log('returndata', forms.secondStep);
    //         locState.currentStep = 2;
    //     }
    //
    // }


    onBeforeMount(() => {
        initModalForms()
    });

    const alertYourChangesNotBeSaved = function (e) {
        e.preventDefault()
    }

    const populateDublicateFormData = () => {
        let passengers = props.modeDraft ? props.draftedPq.pq_passengers : props.duplicateFromPq.pq_passengers
        forms.secondStep.prices.forEach((item, index) => {
            let keys = Object.keys(item.data)
            keys.forEach(key => {
                if(MODEL_PQ_PASSENGERS_TYPES.includes(key) && item.type?.id == '1' && item?.pcc){
                    item.data[key] = passengers[key][index].net;
                }
                if(MODEL_PQ_PASSENGERS_TYPES.includes(key) && item.type?.id == '3'){
                    item.data[key] = passengers[key][index].net;
                }
                if(MODEL_PQ_PASSENGERS_TYPES.includes(key) && item.type?.id == '2' && item?.mileage){
                    item.data[key].net = passengers[key][index].net;
                    item.data[key].miles = passengers[key][index].miles;
                    item.data[key].taxes = passengers[key][index].taxes;
                }
            })
        })
        forms.secondStep.sell_prices = passengers.sell
        forms.secondStep.total_net_prices = passengers.total_net

        forms.secondStep.remark_ext = props.modeDraft ? props.draftedPq.remark_ext : props.duplicateFromPq.remark_ext
        forms.secondStep.remark_int = props.modeDraft ? props.draftedPq.remark_int : props.duplicateFromPq.remark_int
        recalculateTotalForSellPrices()
    }

    const initDuplicateMode = () => {
        locState.duplicateModeLoading = true
        forms.firstStep.code = props.duplicateFromPq.code_dump
        parsePqCode( props.duplicateFromPq.code_dump).then(resp=>{
            if(resp){
                if (locState.pqCodeTableData) {
                    if (locState.pqCodeTableData.pq_flight_legs.every(leg => !leg.flight_details)){
                        locState.pqCodeTableData.pq_flight_legs = props.draftedPq.pq_flight_legs
                    }
                }
                initSecondStepForDuplicateMode()
                locState.duplicateModeShow = true
            }else{
                navigationControl.back()
            }

            locState.duplicateModeLoading = false

        }).then(() => {
            populateDublicateFormData()
        }).catch((e) => {
            console.log(e)
        })
    }


    const initDraftMode = () => {
        locState.draftModeLoading = true;
        forms.firstStep.code = props.draftedPq.code_dump
        parsePqCode( props.draftedPq.code_dump).then(resp=>{
            console.log('locState.pqCodeTableData', locState.pqCodeTableData);
            if (locState.pqCodeTableData) {
                if (locState.pqCodeTableData.pq_flight_legs.every(leg => !leg.flight_details)){
                    locState.pqCodeTableData.pq_flight_legs = props.draftedPq.pq_flight_legs
                }
            }
            console.log('after', locState.pqCodeTableData);
            if(resp){
                initSecondStepForDraftMode()
                locState.draftModeShow = true
            }else{
                navigationControl.back()
            }

            locState.draftModeLoading = false

        }).then(() => {
            populateDublicateFormData()
        }).catch((e) => {
            console.log(e)
        })
    }

    // const initDraftMode = async () => {
    //     try {
    //         locState.draftModeLoading = true;
    //         forms.firstStep.code = props.draftedPq.code_dump;
    //
    //         const resp = await parsePqCode(props.draftedPq.code_dump);
    //
    //         if (resp) {
    //             await initSecondStepForDraftMode(); // если функция тоже асинхронная
    //             locState.draftModeShow = true;
    //         } else {
    //             console.log('back');
    //             navigationControl.back();
    //         }
    //
    //         populateDublicateFormData(); // Раскомментировать, если необходимо вызвать эту функцию
    //     } catch (e) {
    //         console.log(e);
    //     } finally {
    //         locState.draftModeLoading = false;
    //     }
    // };


    onMounted(() => {
        window.onbeforeunload = alertYourChangesNotBeSaved
        if(props.modeDuplicate){
            initDuplicateMode()
        }
        if(props.modeDraft){
            initDraftMode()
        }
    })
    onUnmounted(() => {
        window.onbeforeunload = null
    })


    watch(getShow, (val)=>{
        if(val){
            window.onbeforeunload = alertYourChangesNotBeSaved
        }else{
            window.onbeforeunload = null
        }
    })

    return {
        getShow,
        locState, forms,
        getPassengers,
        navigationControl,
        getTotals,
        getPQHasParsed,

        checkHasOneFilledField,
        tryModalHideHandle,
        showCommonPQModal,
        reCollectPricesTaxesBlocks, recalculateTotalForSellPrices,
    }
}

export default useCreateCommonPq
