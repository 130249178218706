<template>
    <div class="box-request-client-details-actions d-flex flex-wrap" style="justify-content: center">
        <v-container>
            <v-row>
                <v-col cols="auto" v-for="widget in widgets" :key="widget.icon">
                            <v-btn
                                v-bind="props"
                                variant="outlined"
                                class="widget-btn"
                                :style="{ backgroundColor: widget.color }"
                                @click="widget.onClick"
                            >
                                <v-tooltip location="bottom"  activator="parent">{{widget.tooltip}}</v-tooltip>
                                <v-icon :icon="widget.icon" />
                                <v-badge
                                    v-if="widget.count > 0"
                                    :content="widget.count"
                                    color="green"
                                    location="top end"
                                    offset-x="-8"
                                    offset-y="-15"
                                />
                                <span v-if="widget.showRedDot" class="red-dot"></span>
                            </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <ModalClientCallLogs
            :show="locState.callLogsModalShow"
            @hide="locState.callLogsModalShow=false"

        />
        <ModalSendEmail ref="followUpDialog"/>
        <SmsDialog v-model="dialogOpen" :selectedPqs="[]"/>
    </div>
</template>

<script>
export default {
    name: 'RequestClientDetailsActions',
};
</script>

<script setup>
import { computed, reactive, ref } from 'vue';
import ModalClientCallLogs from "@boxes/client/BoxClientConnectionActions/modals/ModalClientCallLogs.vue";
import ModalSendEmail from "@boxes/client/BoxClientConnectionActions/modals/ModalSendEmail.vue";
import {usePageRequestShowStore} from "@store/pages/page-request-edit";
import SmsDialog from "@pages/requests/partials/PartialPriceQuotes/modals/SmsDialog.vue";
import {usePage} from "@inertiajs/vue3";
import axios from 'axios'; // Add axios for HTTP requests
const props = defineProps({
    emailCount: [Number, String],
    phoneCount: [Number, String],
    pqCount: [Number, String],
    followUpValue: [Date, String],
});
const dialogOpen = ref(false);
const openDialog = () => {
    dialogOpen.value = true;
};
const followUpDialog = ref(null);
const emit = defineEmits([
    'emailClick', 'phoneClick', 'pqClick',
    'followUpClick', 'pnrClick', 'voicemailClick',
]);
const locState = reactive({
    sendEmailModalShow: false,
    callLogsModalShow: false,
    clientRating: null,
    voicemail: false,
});
const store = usePageRequestShowStore();

const pqCount = computed(() => store.pq_sent_count);
const emailCount = computed(() => store.follow_up_email_count);
const smsCount = computed(() => store.sms_count);
// const initialComp = computed(()=> page.props.client.data.is_active)
const phoneCallCount = ref(0);
const page = usePage();
const isActiveClient = ref(page.props.client.data.is_active);
const toggleActiveClient = async () => {
    console.log('sdas', isActiveClient.value);
    try {
        const response = await axios.post('/api/client/active', {
            id: page.props.client.data.id, // Здесь используется фиксированный ID клиента, как вы указали
        });

        // Проверяем успешность запроса
        if (response.status === 200) {
            const status = !!isActiveClient.value;
            console.log('status', status)
            // Обновляем состояние только если запрос был успешным
            isActiveClient.value = !status;
            console.log('ssdad', isActiveClient.value);
            console.log('Active client status changed successfully');
        } else {
            console.error('Failed to change active client status');
        }
    } catch (error) {
        console.error('Error changing active client status:', error);
    }
};
const widgets = computed(() => [
    {
        icon: 'mdi-airplane',
        tooltip: `You have sent ${pqCount.value} Price Quotes`,
        count: pqCount.value,
        showRedDot: pqCount .value === 0,
        color: 'white',
        onClick: () => console.log('PQ clicked')
    },
    {
        icon: 'mdi-cellphone-message',
        tooltip: `You have sent ${smsCount.value} SMS`,
        count: smsCount.value,
        showRedDot: smsCount.value === 0,
        color: 'white',
        onClick: () => {
            openDialog()}
    },
    {
        icon: 'mdi-email-edit-outline',
        tooltip: `You have sent ${emailCount.value} follow-up templates`,
        count: emailCount.value,
        showRedDot: emailCount.value === 0,
        color: 'white',
        onClick: () => {
            followUpDialog.value.openDialog();
        }
    },
    {
        icon: 'mdi-phone-incoming-outgoing-outline',
        tooltip: 'Phone calls',
        count: phoneCallCount.value,
        showRedDot: phoneCallCount.value === 0,
        color: 'white',
        onClick: () => {locState.callLogsModalShow = true}
    },
    {
        icon: 'mdi-calendar-alert-outline',
        tooltip: 'Calendar',
        showRedDot: false,
        color: 'white',
        onClick: () => console.log('Calendar clicked')
    },
    {
        icon: 'mdi-account-check',
        tooltip: 'Active client',
        showRedDot: !isActiveClient.value,
        color: isActiveClient.value ? 'rgba(76, 175, 81, 0.7)' : "white",
        onClick: () => toggleActiveClient()
    }
]);
</script>

<style scoped>
.box-request-client-details-actions {
    gap: 11px;
}
.widget-btn {
    width: 38px !important;
    height: 38px !important;
    min-width: 38px !important;
    padding: 0 !important;
    margin: 0 !important;
    border: 1.5px solid #bbb !important;
    position: relative;
    border-radius: 4px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.red-dot {
    position: absolute;
    bottom: -3px;
    right: -3px;
    width: 9px;
    height: 9px;
    background-color: #dc3545;
    border-radius: 50%;
    border: 1px solid white;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

:deep(.v-btn__content) {
    color: #495057;
}

:deep(.v-badge__badge) {
    font-size: 13px;
    font-weight: normal;
    padding: 2px 1px;
    min-width: 20px;
    height: 20px;
    border-radius: 10px;
}

:deep(.v-btn) {
    box-shadow: none !important;
}

:deep(.v-btn:before) {
    background-color: transparent;
}

:deep(.v-btn:hover) {
    border-color: #bbb !important;
}
:deep(.v-container) {
    padding: 0 !important;
}

:deep(.v-row) {
    margin: 0 !important;
}
:deep(.v-col) {
    padding: 4px !important;
}
</style>

