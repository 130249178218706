<template>
    <div class="client-show-general client-info-block  border-bottom-gray">
        <h4>General info</h4>
        <div class="client-info-block-content g-3 row">
            <FieldFirstName
                class="client-info-block-content-item col-4"
                v-model="generalInfo.first_name"
                :errors="errors"
            />
            <FieldMiddleName
                class="client-info-block-content-item col-4"
                v-model="generalInfo.middle_name"
                :errors="errors"
            />
            <FieldLastName
                class="client-info-block-content-item col-4"
                v-model="generalInfo.last_name"
                :errors="errors"
            />
            <acms-v-form-field
                class="client-info-block-content-item col-4"
                :modeSelect="{
                 options:['Company', 'Personal', 'Referral'],
                 }"
                label="Client Source"
                name="source_type"
                placeholder="Select"
                v-model="generalInfo.source_type"
            />
                <div v-if="generalInfo.source_type === 'Referral'" class="client-info-block-content-item col-4">
                    <acms-v-form-field
                        label="Referral ID"
                        placeholder="123123345"
                        modeInput
                        name="referral_id"
                        v-model="generalInfo.referral_id"
                        :errors="generalInfo.errors"
                    />
                </div>
            <FieldSelectGender
                v-model="generalInfo.gender_id"
                :errors="errors"
                class="client-info-block-content-item col-4"
            />
            <FieldSelectContactBy
                v-model="generalInfo.contact_by_id"
                :errors="errors"
                class="client-info-block-content-item col-3"
            />
        </div>
    </div>
    <div class="row border-bottom-gray g-0">
        <div class="client-info-block client-info-block-center col-4 border-right-gray">
            <h4>Contacts</h4>
            <div class="client-info-block-content">
                <BoxEmailsList
                    v-model="contacts.emails"
                    class="mb-3"
                    :errors="errors"
                    :updatedData="parentProps?.contacts?.emails?.data"
                />
                <BoxPhoneNumbersAndBestTimeToCall
                    v-model:phone-numbers="contacts.phone_numbers"
                    :updated-phone-numbers="parentProps?.contacts?.phone_numbers?.data"
                    v-model:best-time-to-call="contacts.best_time_to_call"
                    :errors="errors"
                />
            </div>
        </div>
        <div class="client-info-block client-info-block-center  col-4 border-right-gray">
            <h4>Location & Timezone</h4>
            <div class="client-info-block-content">
                <FieldSelectCountry
                    v-model="locationTimezone.country_id"
                    :initValue="locationTimezone.country"
                    :errors="errors"
                />
                <acms-v-form-field
                    :modeSelect="{
                                 search:true,
                                searchAsync: 'infohub.getStates',
                                searchAsyncParams:{
                                    country_id: locationTimezone.country_id
                                },
                                outValueKey: 'id',
                                placeholderSelect:'Search',
                                initialValue: locationTimezone.state,
                                minSymbolsForSearch: 2
                            }"
                    label="State"
                    name="state_id"
                    :errors="errors"
                    v-model="locationTimezone.state_id"
                />
                <acms-v-form-field
                    :modeSelect="{
                                search:true,
                                searchAsync: 'infohub.getCities',
                                searchAsyncParams:{
                                    country_id: locationTimezone.country_id
                                },
                                outValueKey: 'id',
                                placeholderSelect:'Search',
                                initialValue: locationTimezone.city
                            }"
                    label="City"
                    name="city_id"
                    :errors="errors"
                    v-model="locationTimezone.city_id"
                />

                <acms-v-form-field
                    :modeSelect="{
                                 options:$page.props?.optionsForSelect?.timezoneOption ?? [],
                                 search:true,
                                 searchAsync: 'infohub.getTimezonesList',
                                 outValueKey: 'id',
                                 placeholderSelect:'Search',
                                 initialValue: locationTimezone.timezone,
                                 toggleKeyTitle: setToggleTitleForTimezone,
                                 toggleTitleAutoOverflow:true,
                            }"
                    label="Timezone"
                    name="timezone_id"
                    :errors="errors"
                    v-model="locationTimezone.timezone_id"
                />
            </div>
        </div>
        <div class="client-info-block client-info-block-center  client-info-block-ffprogramms col-4">
            <h4>FF programs</h4>
            <div class="client-info-block-content">
                <BoxFFPrograms
                    :modelValue="ffPrograms"
                    @update:delete-ids="$emit('update:ffProgramsDeleteIds', $event)"
                    @update:modelValue="$emit('update:ffPrograms', $event)"
                    :errors="errors"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "EntityClientGeneralInfo"
}
</script>

<script setup="">
import {usePage} from "@inertiajs/vue3";
import {BoxEmailsList, BoxFFPrograms} from "@boxes/common";
import FieldFirstName from "@components/app/fields/FieldFirstName.vue";
import FieldLastName from "@components/app/fields/FieldLastName.vue";
import FieldMiddleName from "@components/app/fields/FieldMiddleName.vue";
import FieldSelectContactBy from "@components/app/fields/FieldSelectContactBy.vue";
import FieldSelectGender from "@components/app/fields/FieldSelectGender.vue";
import BoxPhoneNumbersAndBestTimeToCall from "../../boxes/common/BoxPhoneNumbersAndBestTimeToCall.vue";
import FieldSelectCountry from "@components/app/fields/FieldSelectCountry.vue";
import {watch} from "vue";

const props = defineProps({
    parentProps: Object,
    generalInfo: Object,
    contacts: Object,
    locationTimezone: Object,
    ffPrograms: Array,
    ffProgramsDeleteIds: Array,
    errors: Object,
})
const $page = usePage();
const setToggleTitleForTimezone = (object) => {
    const {currentValueObject} = object
    if (currentValueObject) {
        const {title, gmt, country} = currentValueObject

        const correctGmt = gmt.includes('-') ? gmt : '+' + gmt

        return `${title}, ${country?.title}, GMT${correctGmt}`
    }
}
watch(() => props.generalInfo.source_type, (newValue) => {
    if (newValue !== 'Referral') {
        props.generalInfo.referral_id = null
    }
}, { immediate: true })
</script>

<style scoped lang="scss">


@media only screen and (max-width: 1630px) {
    .client-info-block-content-item {
        width: 33.3%;
    }
    .client-info-block-center{
        width: 50%;
    }
    .client-info-block-ffprogramms{
        width: 100%;
        border-top: 1px solid #CED4DA;
    }
}
</style>
