<template>
    <div class="phone-container variant-2">
        <!-- Кнопка вызова -->
        <v-btn
            class="eye1-btn"
            style="margin-right: 5px!important"
            icon
            elevation="0"
            @click="handleClick"
            width="25"
            height="25"
        >
            <v-icon :size="19">
                mdi-phone-outgoing-outline
            </v-icon>
        </v-btn>
        <!-- Всплывающее сообщение -->
        <div :class="['calling-overlay', { show: isCalling }]">
            <i class="fas fa-phone phone-icon"></i>
            <div class="calling-animation">
                <div class="circle-dot"></div>
                <div class="circle-dot"></div>
                <div class="circle-dot"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';

export default {
    name: 'CallButton',
    props: {
        phone: {
            type: String,
            required: true
        }
    },
    emits: ['start-call'],
    setup(props, { emit }) {
        const isCalling = ref(false);

        const handleClick = async () => {
            // Вызываем функцию из родительского компонента
            await emit('start-call', props.phone);

            // Запускаем анимацию
            isCalling.value = true;
            setTimeout(() => {
                isCalling.value = false;
            }, 5000);
        };

        return { isCalling, handleClick };
    },
};
</script>

<style scoped>

body {
    font-family: 'Inter', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #f4f4f9;
    margin: 0;
}

.phone-container {
    position: relative;
    display: inline-block;
}

.call-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
}

.call-button:hover {
    background-color: #0056b3;
}

.calling-overlay {
    position: absolute;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
    padding: 8px 12px;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 10px;
    opacity: 0;
    background: white;
    color: #28a745;
    border: 1px solid #28a745;
    transition: opacity 0.3s ease-in-out;
}

.calling-overlay.show {
    opacity: 1;
}

@keyframes slightPulse {
    0%, 100% {
        transform: translateX(-50%) scale(1);
    }
    50% {
        transform: translateX(-50%) scale(1.08);
    }
}

.calling-animation {
    display: flex;
    align-items: center;
    gap: 8px;
}

.circle-dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #28a745;
    animation: bounce 1.2s infinite ease-in-out;
}

.circle-dot:nth-child(2) {
    animation-delay: 0.2s;
}

.circle-dot:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes bounce {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}

.phone-icon {
    color: #28a745;
    animation: ring 1s infinite;
}

@keyframes ring {
    0%, 100% { transform: rotate(-10deg); }
    50% { transform: rotate(10deg); }
}
</style>
