import {useAlertsManagerStore} from "@store/plugins/alerts-manager";
import {onBeforeMount, onMounted, onUnmounted, reactive,} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import {
    getFormattedEmailsFor,
    getFormattedPhoneNumbersFor,
} from "@services/FormattedDataForServerService";
import {getRoute} from "@plugins/useRoutes";
import {cloneDeep} from "lodash";

import {storeToRefs} from "pinia";
import {usePageClientCreateStore} from "@store/pages/page-create-client-store";
import {clientModel} from "@/models/client.model";

import {
    NOT_FILLED_REQUEST_ALERT,
    NOT_FILLED_REQUEST_AND_LINKED_CONTACT_ALERT,
    FILLED_LINKED_CONTACT_AND_NOT_FILLED_REQUEST_ALERT,
    REDIRECT_INDEX,
    REDIRECT_EDIT,
} from '../constants/clientCreate.constants'
import useBoxClientRequestsAndLinkedContactsComponent
    from "@boxes/client/BoxClientRequestsAndLinkedContacts/useBoxClientRequestsAndLinkedContacts.component";
import {getFormattedBestTimeToCall} from "@services/FormattedDataForServerService";
import useControlRedirectService from "@services/ControlRedirectService";

export default function (params = {}) {
    const {props} = params
    const $page = usePage()

    const AlertsManager = useAlertsManagerStore()

    const locState = reactive({
        client: null,  // {id: 1}
        clientDetailsForm: null,
        clientSource: 'Company',
        saveAndExitModalAlert: false,
        saveModalAlert: false,
        saveModalAlertContent: null,
        needToRedirectPageTo: null,
        canSaveRequestsAndLinkedContacts: false,
    });

    const PageClientCreateStore = usePageClientCreateStore()
    const {submitProcessing} = storeToRefs(PageClientCreateStore)
    const BoxClientRequestsAndLinkedContactsComponent = useBoxClientRequestsAndLinkedContactsComponent()
    const {removeOnBeforeUnloadListeners, addOnBeforeUnloadListeners} = useControlRedirectService(
        {
            rejectFunctions: [() => PageClientCreateStore.setSubmitProcessing(false)]
        }
    )

    const submitClientForm = () => {
        try {
            const route = locState.client?.id ? ['clients.update', locState.client.id] : ['clients.store']
            PageClientCreateStore.setSubmitProcessing(true)
            return new Promise(resolve => {
                locState.clientDetailsForm.transform(data => {
                    const {best_time_to_call, names, gender_id, contact_by_id, phone_numbers, emails, ...rest} = data;
                    const {best_time_call_from, best_time_call_to} = getFormattedBestTimeToCall(best_time_to_call)

                    return {
                        ...names,
                        best_time_call_from,
                        best_time_call_to,
                        gender_id,
                        contact_by_id,
                        emails: getFormattedEmailsFor.backend(emails),
                        phone_numbers: getFormattedPhoneNumbersFor.backend(phone_numbers),
                        ...rest,
                        id: locState.client?.id
                    }
                })[locState.client?.id ? 'put' : 'post'](getRoute(...route), {
                    preserveScroll: true,
                    preserveState: true,
                    onSuccess() {
                        const existServerError = $page.props.flash?.error

                        if (existServerError) {
                            PageClientCreateStore.setSubmitProcessing(false)
                            return
                        }

                        if (locState.client) {
                            AlertsManager.add('Client successfully updated!')
                        } else {
                            AlertsManager.add('Client successfully created!')
                        }

                        const flashClient = $page.props.flash?.success?.client?.data;

                        if (flashClient) {
                            locState.client = flashClient
                        }
                        toggleShowErrorsForRequestFields(false)
                        toggleShowErrorsForLinkedContactFields(false)
                        PageClientCreateStore.setSubmitProcessing(false)
                        resolve(true)
                    },
                    onError(e) {
                        toggleShowErrorsForRequestFields(false)
                        PageClientCreateStore.setErrorsForRequestFieldsShow(false)
                        toggleShowErrorsForLinkedContactFields(false)
                        AlertsManager.add('Client details has errors', 'danger')
                    },
                    onFinish() {
                        PageClientCreateStore.setSubmitProcessing(false)
                    }
                });
            })
        } catch (e) {
            AlertsManager.add('Client details has some errors', 'danger')
            PageClientCreateStore.setSubmitProcessing(false)
        }

    }

    const toggleShowErrorsForRequestFields = (val = true) => {
        PageClientCreateStore.setErrorsForRequestFieldsShow(val)
    }
    const toggleShowErrorsForLinkedContactFields = (val = true) => {
        PageClientCreateStore.setErrorsForLinkedContactFieldsShow(val)
    }
    const preValidateClient = () => {
        const {names, phone_numbers, emails} = locState.clientDetailsForm

        const phoneNumbersList = phone_numbers?.data ?? phone_numbers
        const emailsList = emails?.data ?? emails
        const isFilledRequiredFields = Boolean(names.first_name && (phoneNumbersList?.[0]?.text || emailsList?.[0]?.text))

        const showClientErrors = () => {
            locState.clientDetailsForm?.clearErrors()
            AlertsManager.add('Client details has errors', 'danger')

            if (!names.first_name || names.first_name?.length == 0) {
                locState.clientDetailsForm.setError(`first_name`, 'This field is required.',);
            }

            if (!phoneNumbersList?.[0].text && !emails?.[0].text) {
                phoneNumbersList?.forEach((item, index) => {
                    if (!item.text || item.text == '') {
                        locState.clientDetailsForm.setError(`phone_numbers.${index}.phone`, 'This field is required.',);
                    }
                })
                emailsList?.forEach((item, index) => {
                    if (!item.text || item.text == '') {
                        locState.clientDetailsForm.setError(`emails.${index}`, 'This field is required.',);
                    }
                })
            }
        }

        return {
            isFilledRequiredFields,
            showClientErrors
        }
    }

    const saveHandle = (exit = false) => {
        const {isFilledRequiredFields, showClientErrors} = preValidateClient()

        if (!isFilledRequiredFields) {
            showClientErrors?.()
            return
        }
        const {checkIsFilledRequiredFields} = BoxClientRequestsAndLinkedContactsComponent

        const {
            requestIsFilled,
            linkedContactIsFilled,
            linkedContactIsFilledNotRequiredFields
        } = checkIsFilledRequiredFields?.()

        locState.saveModalAlertContent = null;

        if (requestIsFilled == false || linkedContactIsFilled == false) {

            if (requestIsFilled == false && linkedContactIsFilled) {
                toggleShowErrorsForRequestFields()
                locState.saveModalAlertContent = FILLED_LINKED_CONTACT_AND_NOT_FILLED_REQUEST_ALERT;
            } else if (requestIsFilled == false && linkedContactIsFilledNotRequiredFields) {
                toggleShowErrorsForRequestFields()
                toggleShowErrorsForLinkedContactFields()
                locState.saveModalAlertContent = NOT_FILLED_REQUEST_AND_LINKED_CONTACT_ALERT;
            } else if (requestIsFilled == false) {
                toggleShowErrorsForRequestFields()
                locState.saveModalAlertContent = NOT_FILLED_REQUEST_ALERT;
            }

            if (!locState.saveModalAlertContent) {
                saveAndGoHandle(exit)
            } else {
                if (exit) {
                    locState.saveAndExitModalAlert = true;
                } else {
                    locState.saveModalAlert = true;
                }
            }

        } else {
            saveAndGoHandle(exit)
        }
    };

    const saveAndGoHandle = (toIndex = false) => {
        locState.needToRedirectPageTo = toIndex ? REDIRECT_INDEX : REDIRECT_EDIT;
        removeOnBeforeUnloadListeners?.()
        const sendRequestsAndLinked = () => {
            toggleShowErrorsForRequestFields(false)
            toggleShowErrorsForLinkedContactFields(false)

            locState.canSaveRequestsAndLinkedContacts = true
            setTimeout(() => {
                locState.canSaveRequestsAndLinkedContacts = false
            }, 500);
        }

        locState.saveAndExitModalAlert = false;
        locState.saveModalAlert = false;

        if (locState.client?.id) {
            if (locState.clientDetailsForm.isDirty) {
                submitClientForm().then(sendRequestsAndLinked)
            } else {
                sendRequestsAndLinked()
            }
        } else if (locState.client == null) {
            submitClientForm().then(sendRequestsAndLinked)
        }
    }

    const redirectToPage = () => {
        removeOnBeforeUnloadListeners?.()

        const redirectTo = locState.needToRedirectPageTo;

        if (redirectTo == REDIRECT_INDEX) {
            router.get(getRoute(REDIRECT_INDEX))
        } else if (redirectTo == REDIRECT_EDIT) {
            router.get(getRoute(REDIRECT_EDIT, locState.client?.id))
        }
    }

    const checkToRedirectPage = (allSaved) => {
        if (allSaved) {
            redirectToPage()
        }
    }

    onBeforeMount(() => {
        locState.clientDetailsForm = useForm(cloneDeep(clientModel));
    });

    onMounted(() => {
        addOnBeforeUnloadListeners?.()
    })

    const removeReferralHandle = () =>{
        locState.clientDetailsForm.referral_id = null

        locState.clientDetailsForm.clearErrors('referral_id')
    }

    return {
        saveHandle, saveAndGoHandle,
        locState, submitProcessing,
        checkToRedirectPage,
        removeReferralHandle,
    }

}
