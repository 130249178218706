export const clientModel = {
    names: {
        first_name: '',
        last_name: '',
        middle_name: null,
        no_name: false,
    },
    source_type: 'Company',
    emails: [],
    phone_numbers: [],

    best_time_to_call: {
        from: null,
        to: null,
    },

    gender_id: null,
    contact_by_id: null,

    referral_id: null,
    notes: null,
};

export const linkedContactModel = {
    names: {
        first_name: null,
        last_name: null,
        middle_name: null,
        no_name: false,
    },

    emails: [],

    phone_numbers: [],

    best_time_to_call: {
        from: null,
        to: null,
    },

    type_id: null,
    notes: null,
    gender_id: null,
    contact_by_id: null,

    ff_programs: null,
}

export const LINKED_CONTACT_MODEL_SIMPLE_KEYS = ['type_id','notes','gender_id','contact_by_id']


export const clientPassportDataModel = {
    first_name: null,
    last_name: null,
    middle_name: null,

    passport_number: null,
    issuing_country_id: null,
    expiration_date: null,
    birth_date: null,
    is_main_passenger: 0,

    gender_id: null,
    // nationality: null,
    nationality_id:null,
    ktn: {
        type_id: null,
        code: null,
        redress: null
    },
}
