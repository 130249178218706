<template>
    <Head :title="'Request ID: '+ getCurrentRequest?.id+' - VIP Fares'"></Head>
    <AppMainLayout
        :title="'Request ID: '+ getCurrentActiveRequest?.id"
        :title2="'created on ' + props.currentRequest.data.created_at + ' RMO'"
        :breadcrumbs="[{title: 'Requests', link: getRoute('requests')}, `ID ${getCurrentRequest?.id}`,]"
    >
        <template #header-right>
            <div class="d-flex ms-auto">
                <template v-if="locState.mainTabsActiveTab == 'price-quotes'">
                    <div id="request-page-head-tab-pq" class="me-2"></div>
                </template>
                <template v-if="locState.mainTabsActiveTab == 'contact-info'">
                    <div id="request-page-head-tab-contact-info" class="me-2"></div>
                </template>
                <!-- <ElementDropdownNextActions/> -->
            </div>
        </template>
        <template #content>
            <div class="row g-3" style="display: flex; flex-wrap: nowrap">
                <div class="requests-show-client-details page-inner-sidebar col-2" style="margin-right: 15px; min-width: 320px">
                    <BoxCardRequestClientDetails
                        :agent="agent"
                        :client="client.data"
                        :currentRequest="getCurrentActiveRequest ?? getCurrentRequest"
                        @open-accordion="handleAccordionOpening"
                        @open-alt-accordion="handleAccordionOpenAlt"
                    />
                </div>
                <div class="client-show-full-info page-inner-content d-width1" style="padding: 0!important;">
                    <acms-v-card title="Add data" v-if="tabPassengers.formShow">
                        <BoxPassportDataEdit
                            :modelValue="tabPassengers.formData"
                            :mainClientId="getClientId"
                            :requestId="getCurrentActiveRequestItem.id"
                            show-add-button
                            @cancel="tabPassengersHandlers.cancel"
                            :primaryPassenger="!hasPassportData"
                        />
                    </acms-v-card>
                    <acms-v-tabs v-if="!tabPassengers.formShow"
                                 v-bind="TABLES_PROPS.TABS_REQUEST_EDIT_PAGE"
                                 :items="getTabsItems"
                                 :active="locState.mainTabsActiveTab"
                                 @beforeChangeTab="onChangeTabHandle"
                                 parent-control
                                 active-index="0"
                                 :nav-quantity="{
                                 'request' :  1 + requestChildren.data?.length ?? 0,
                                 'all-requests' :  String(allRequests?.meta.total)
                     }">
                        <template #tab-price-quotes>
                            <PartialPriceQuotes
                                :clientId="getClientId"
                                :requests="getCurrentRequestWithChildren"
                                :currentActiveRequestTab="locState.requestsTabsActiveTab"
                                :currentActiveRequestItem="getCurrentActiveRequestItem"
                                @changeActiveRequestTab="locState.requestsTabsActiveTab = $event"
                                :currentActiveRequestPqs="getCurrentActiveRequestPqsArray"
                                :currentActiveRequestAgentPqsArray="getCurrentActiveRequestAgentPqsArray"
                                :pqs="pqs"
                                :agents="props.agent"
                            />
                        </template>
                        <template #tab-request>
                            <div class="request-inner-items">
                                <div class="request-inner-items-header request-row-titles-template">
                                    <div class="request-row-titles-template-item text-uppercase text-dark-gray ">#</div>
                                    <div :class="tableColumnHeadClassesString">Request ID</div>
                                    <div :class="tableColumnHeadClassesString">Flight details</div>
                                    <div :class="tableColumnHeadClassesString">Passengers</div>
                                </div>
                                <PartialRequestsAccordion
                                    :clientId="getClientId"
                                    :items="getCurrentRequestWithChildren"
                                    :responseOnlyKey="['allRequests', 'currentRequest', 'requestParent','requestChildren']"
                                    :active-index="accordionIndex"
                                />
                                <div class="mt-4" style="margin-left: 12px;">
                                    <acms-v-form-field
                                        :mode-textarea="{
                                         minHeight : 56
                                        }"
                                        label="Notes about client"
                                        v-model="locState.notes_about_client"
                                    />
                                </div>
                            </div>
                        </template>
                        <template #tab-all-requests>
                            <BoxTableRequests
                                :collection="allRequests"
                                @addButtonClick="()=> router.get(getRoute('clients.edit',{id:getClientId}),{createNewRequest:true})"
                                v-model:page="requestTableForm.page"
                                v-model:entries="requestTableForm.limit"
                                v-model:search="requestTableForm.search"
                                @sortChange="requestTableSortHandle"
                                :agents="agents_to_select"
                                @update:selectedRows="updateSelectedRows"
                                @handle:assignAgent="handleAssignAgent"
                                :hideAssignAgentBtn="!backendPermissions.allowAssignAgents"
                            />
                        </template>
                        <template #tab-contact-info>
                            <PartialContactInfo
                                :response-only-key="['client', 'linkedContactsRest']"
                                :client="client"
                                :linkedContactsRest="linkedContactsRest"
                                :currentRequestId="getCurrentRequest.id"
                                :show-details="locState.showDetails"
                            />
                        </template>
                        <template #tab-passengers>
                            <PartialPassengersTableAndSelect
                                :show="!tabPassengers.formShow"
                                :passengers="passengers"
                                :requests="getCurrentRequestWithChildren"
                                :currentActiveRequestTab="locState.requestsTabsActiveTab"
                                :pqs="pqs"
                                @edit="tabPassengersHandlers.edit"
                                @create="tabPassengersHandlers.create"
                                @changeActiveRequestTab="locState.requestsTabsActiveTab = $event"
                                :allowCreate="backendPermissions?.allowCreatePassengers"
                            />
                        </template>
                        <template #tab-activity-feed></template>
                        <template #tab-agents>
                            <acms-v-table v-bind="TABLES_PROPS.TABLE_AGENTS_PROPS" :data="tabAgentsData.tableData"
                                          @editAction="()=>{}"/>
                        </template>
                    </acms-v-tabs>
                </div>
            </div>
        </template>
    </AppMainLayout>
</template>

<script>
import axios from 'axios';
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";

export default {
    name: 'PageRequestShow',
    data() {
        return {
            selectedRows: [],
        };
    },
    methods: {
        updateSelectedRows(newSelectedRows) {
            this.selectedRows = newSelectedRows;
        },
        handleAssignAgent(data) {
            const requestIds = this.selectedRows.map(row => row.id);

            console.log('Selected Request IDs:', requestIds);
            axios.post('/requests/assign-agent', {
                agent_ids: data.agent,
                request_ids: requestIds
            })
                .then(response => {
                    console.log('Success:', response.data.message);
                    const AlertsManager = useAlertsManagerStore();
                    AlertsManager.add('Agent successfully assigned to Requests');
                    this.$emit('agent-assigned', response.data.requests);
                })
                .catch(error => {
                    console.error('Error:', error);
                    const AlertsManager = useAlertsManagerStore();
                    AlertsManager.add('Please Select Requests and/or Agents', 'danger');
                });
        }
    }
};
</script>

<script setup="">
import {Head, router} from '@inertiajs/vue3'
import useRequestEditComponent from './composables/useRequestEdit.component';
import useTableSortable from "@ui/Table/useTableSortable";
import {getRoute} from "@plugins/useRoutes";
import {
    PartialRequestsAccordion,
    PartialPassengersTableAndSelect,
    PartialContactInfo,
    PartialPriceQuotes
} from "./partials";
import AppMainLayout from "@layouts/AppMainLayout.vue";
import * as TABLES_PROPS from "@pages/requests/constants";
import {BoxPassportDataEdit} from "@boxes/common";
import {BoxCardRequestClientDetails, BoxTableRequests} from '@boxes/request';
import {computed, onMounted, ref} from "vue";
import {useBackendMiddlewarePropsService} from "@services";
import {usePageRequestShowStore} from "@store/pages/page-request-edit";

const emit = defineEmits([''])

const props = defineProps({
    currentRequest: Object,
    requestParent: Object,
    requestChildren: Object,
    client: Object,
    allRequests: Object,
    pqs: Object,
    agent_pqs: Object,
    linkedContactsRest: Object,
    passengers: Object,
    agent: Array,
    agents_to_select: Array,
    hasPassportData: Boolean,
    follow_up_email_count: Boolean,
});
function handleAccordionOpening(index) {
    locState.mainTabsActiveTab = 'request';
    accordionIndex.value = index;
}
function handleAccordionOpenAlt(type) {
    if (type === 'contact-info-custom') {
        locState.mainTabsActiveTab = 'contact-info';
        locState.showDetails = true;
    }
    else {
        locState.mainTabsActiveTab = type;
        locState.showDetails = false;
    }

}
const accordionIndex = ref(-1);
const {
    locState, getClientId, getCurrentRequest, getCurrentRequestWithChildren, getCurrentActiveRequest,
    getCurrentActiveRequestItem, getCurrentActiveRequestPqsArray,getCurrentActiveRequestAgentPqsArray,
    tabPassengers, tabPassengersHandlers,
    tabAgentsData,
    getAssignedAgents,
    onChangeTabHandle,
} = useRequestEditComponent({props, emit},)
const {backendPermissions} = useBackendMiddlewarePropsService()
const tableColumnHeadClassesString = 'request-row-titles-template-item text-uppercase text-dark-gray white-space-nowrap'

const {
    tableForm: requestTableForm,
    tableSortHandle: requestTableSortHandle,
} = useTableSortable({
    route: getRoute('requests.getRequestCollections', {'clientRequest': getCurrentRequest.value.id}),
    sendParams: {
        sendType: 'post',
        only: ['allRequests'],
    }
})

const getTabsItems = computed(() => {
    const items = {
        'price-quotes': 'PQs',
        'request': 'Request',
        'all-requests': 'All Requests',
        'contact-info': 'Contact Info',
        'passengers': 'Passengers',
        'activity-feed': 'Activity Feed',
        'agents': 'Agents'
    }

    if(!backendPermissions?.allowViewRequestActivityFeed){
        delete items['activity-feed']
    }
    if(!backendPermissions?.allowViewPassengers){
        delete items['passengers']
    }
    return items

})


const store = usePageRequestShowStore()

const pqCount = computed(() => {
    const pageProps = props.currentRequest;
    const id = pageProps.data.id;
    const pqs = props.pqs[id] || [];
    return pqs.filter(pq => pq.data && pq.data.send_logs && pq.data.send_logs.length > 0).length;
});

const emailCount = computed(() => {
    console.log("props.currentRequest", props);
    return props.follow_up_email_count ?? 0;
});

const smsCount = computed(() => {
    return props.currentRequest.data.sms_count ?? 0;
});

onMounted(() => {
    store.setPQSentCount(pqCount.value)
    store.setFollowUpEmailCount(emailCount.value)
    store.setSMSCount(smsCount.value)
})
</script>
<style lang="scss">
.pq-request-short-info-btn {
    display: flex;
}

.request-inner-items-header {
    div {
        padding: 12px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
    }
}

.request-row-titles-template {
    display: flex;
    align-items: center;
    width: 100%;

    .request-row-titles-template-item {

        & > div {
            flex-grow: 0;
        }

        &:first-child {
            width: 52px;
            flex-shrink: 0;
        }

        &:nth-child(2) {
            width: 120px;
            flex-shrink: 0;
        }

        &:nth-child(3) {
            max-width: 450px;
            width: 100%;
        }

        &:nth-child(4) {
            max-width: 450px;
            width: 100%;
        }
    }
}

.request-main-tabs {
    .nav-tabs-alt {
        border: 0;
    }

    .request-main-tabs-nav {
        border-bottom: 1px solid #CED4DA;
    }
}

.requests-tabs-block {
    .nav-item {
        &:first-child {
            .nav-link {
                border-radius: 5px 0 0 5px;
            }
        }

        &:nth-child(2) {
            border-radius: 5px 0 5px 0;

            .nav-link {
                border-radius: 0 5px 5px 0;
            }
        }
    }

    .nav-link {
        border: 1px solid #E9ECEF;
        background-color: #fff;

        &:hover {
            color: #334155;
        }

        &.active {
            background-color: #343A40;
            border: 1px solid #343A40;

            span {
                color: #fff;
            }

            .text-uppercase {
                color: #CED4DA !important;
            }
        }

        .text-uppercase {
            color: #6C757D !important;
        }
    }
}

.d-width1 {
    width: 79%;
}

@media (max-width: 1635px) {
    .d-width1 {
        width: 76%;
    }
}
</style>
