import { defineStore } from 'pinia'

export const usePageRequestShowStore = defineStore('pageRequestEdit', {
    state: () => ({
        commonPQModalShow: false,
        revenuePQModalShow: false,
        awardPQModalShow: false,
        followUpCalendarShow: false,
        closeRequestModalShow: false,
        revenuePQDuplicateModalShow: false,
        awardExchangePQModalShow: false,
        requestPNRModalShow: false,

        // Новые свойства
        sms_count: 0,
        follow_up_email_count: 0,
        pq_sent_count: 0
    }),

    actions: {
        showAwardPQModal(bool = true) {
            this.awardPQModalShow = bool
        },
        showRevenuePQModal(bool = true) {
            this.revenuePQModalShow = bool
        },
        showCommonPQModal(bool = true) {
            this.commonPQModalShow = bool
        },
        showFollowUp(bool = true) {
            this.followUpCalendarShow = bool
        },
        showCloseRequestModal(bool = true) {
            this.closeRequestModalShow = bool
        },
        showRevenuePQDuplicateModal(bool = true) {
            this.revenuePQDuplicateModalShow = bool
        },
        showAwardExchangePQModal(bool = true) {
            this.awardExchangePQModalShow = bool
        },
        showRequestPNRModal(bool = true) {
            this.requestPNRModalShow = bool
        },

        // Новые actions для управления счетчиками
        incrementSMSCount() {
            this.sms_count++
        },
        incrementFollowUpEmailCount() {
            this.follow_up_email_count++
        },
        incrementPQSentCount() {
            this.pq_sent_count++
        },
        resetCounts() {
            this.sms_count = 0
            this.follow_up_email_count = 0
            this.pq_sent_count = 0
        },
        setSMSCount(count) {
            this.sms_count = count
        },
        setFollowUpEmailCount(count) {
            this.follow_up_email_count = count
        },
        setPQSentCount(count) {
            this.pq_sent_count = count
        },
    },
})

