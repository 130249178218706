<template>

    <div class="acms-v-btn btn d-flex align-items-center text-white bg-gray-light p-1"
         title="Client rate"
         style="width: 36px; height: 36px"
         v-click-outside="hideHandle"
    >
        <div>
            <acms-v-btn icon="star" icon-size="19" @click="locState.show = true"
                        style="color: black">
            </acms-v-btn>
        </div>
        <div class="acms-v-ratinger-content" v-if="locState.show">
            <div class="acms-v-ratinger-head ">
                <div class="acms-v-ratinger-title">{{ title }}</div>

                <div class="acms-v-ratinger-reset text-primary"
                     @click="()=>clickHandle(0)"
                >Reset
                </div>
            </div>
            <div class="acms-v-ratinger-stars">
                <div class="acms-v-ratinger-star-item" v-for="star in STARS"
                     @click="()=>clickHandle(star)"
                     :data-star="star">
                    <div :title="star">
                        <acms-v-icon name="star" size="24" :color="getStarClass(star)"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'acms-v-ratinger',
};
</script>
<script setup>
import { onBeforeMount, reactive } from 'vue';

const STARS = [1, 2, 3, 4, 5];
const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    title: String,
    modelValue: {
        type: [Number, String],
        default: 0,
    },
});

const locState = reactive({
    show: false,
    selected: props.modelValue,
});

const getStarClass = (numb) => {
    return numb <= locState.selected ? 'text-warning' : 'text-gray';
};
const clickHandle = (numb) => {
    locState.selected = numb;
    emit('update:modelValue', locState.selected);
};
const hideHandle = () => {
    locState.show = false;
};
onBeforeMount(() => {
    locState.selected = props.modelValue ?? 0;
});

</script>
<style lang="scss">
.acms-v-ratinger {
    position: relative;
}
.acms-v-ratinger-content {
    position: absolute;
    padding: 12px;
    box-shadow: 0px 0px 24px rgba(31, 41, 55, 0.1);
    background-color: #fff;
    border-radius: 4px;
    top: 110%;
}
.acms-v-ratinger-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}
.acms-v-ratinger-reset {
    cursor: pointer;
}
.acms-v-ratinger-stars {
    display: flex;
}
.acms-v-ratinger-star-item {
    &:not(:last-child) {
        margin-right: 8px;
    }
}
</style>
